import { useNavigate } from "react-router-dom";
import doRequest from "../../util/axios";
import TextInputField from "../../util/fields/text-field";
import FormContainer from "../../util/form-container";
import SelectField from "../../util/fields/select-field";

const DeviceForm = ({ title, buttonText, data, }: any) => {
    const navigate = useNavigate();
    return <FormContainer
        data={data}
        title={title}
        requiredFields={[]}
        buttonText={buttonText}
        onSubmit={(async (d: any) => {
            console.log(d)

            if (d._id) {
                const n = await doRequest<any>(`device/${d._id}`, "PATCH", d)
                console.log(n)
                navigate && navigate('../', { replace: false });
            } else {
                const n = await doRequest<any>('device', "POST", d)
                console.log(n)
                navigate && navigate('../', { replace: false });
            }

        })}>


        <TextInputField name="code" label="Code" required={true} />
        <TextInputField name="name" label="Name" required={true} />
        <SelectField name="plant" label="Plant" requestType="GET" requestUrl="plant" required={true} />
    </FormContainer>
}


export default DeviceForm;