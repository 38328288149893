import TextInputField from "../../util/fields/text-field"
import FormContainer from "../../util/form-container"

const Profile = () => {





    return <FormContainer onSubmit={async (d) => {
d
    }}

        requiredFields={['currentPassword', 'password', 'password2']}
        buttonText="Update Password"
    >
        <TextInputField name="currentPassword" label="Current Password" type="password" />
        <TextInputField name="password" label="New Password" type="password" />
        <TextInputField name="password2" label="New Password Again" type="password" />

    </FormContainer>
}


export default Profile