
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration' // import plugin
import relativeTime from 'dayjs/plugin/relativeTime' // import plugin
import { useEffect, useState } from 'react';
dayjs.extend(duration) // use plugin
dayjs.extend(relativeTime);


interface iDisplayDurationProps{
    from:Date|string|number; 
    to?:Date|string|number; 
}

const DisplayDuration = ({from, to=new Date()}:iDisplayDurationProps) =>{
    const [duration, setDuration] = useState(()=>{
        const dFrom:dayjs.Dayjs = dayjs(from) 
        const dTo:dayjs.Dayjs = dayjs(to);
        const ddd = dayjs.duration(dFrom.diff(dTo))
        return ddd.humanize(false);
    });


    useEffect(()=>{
        const dFrom:dayjs.Dayjs = dayjs(from)
        const dTo:dayjs.Dayjs = dayjs(to);
        const ddd = dayjs.duration(dFrom.diff(dTo))
     //   console.log(ddd, ddd.humanize())
        setDuration(ddd.humanize(false));
    }, [from, to])
    return <>{duration}</>
}


export default DisplayDuration;