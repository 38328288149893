import {  Box,Typography } from "@mui/material";
import { iActionSent } from "../../interfaces/iDevice";
import DebugButton from "../../util/debugButton";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";


   
interface iDSProp{
    actions: iActionSent[]
   // deviceId:string;
}







const DeviceActonsSent = ({actions}:iDSProp) =>{

    return <>
    <Typography variant="h6">Commands</Typography>
    <Box sx={{height: 600}}>
    <DataGrid
        getRowId={r => r.id}
        disableColumnSelector
        rows={actions}
        initialState={{
          sorting: {
            sortModel: [{ field: 'created', sort: 'desc' }],
          },
        }}
        rowHeight={72}
        columns={[
            {
                field: 'cmd', disableColumnMenu: false, headerName: "Cmd", flex: 2, minWidth: 50
            },
            {
              field: 'created', disableColumnMenu: false, headerName: "Created", flex: 1, minWidth: 175, type: 'datetime', 
              renderCell: (params: GridRenderCellParams) => <>{params && params.value && params.value.toString().replace('GMT+0000 (Greenwich Mean Time)', '')}</>,
              valueGetter: ({ value }) => value && new Date(value),
            },
            {
              field: 'sent', disableColumnMenu: false, headerName: "Sent", flex: 1, minWidth: 175, type: 'datetime', 
              renderCell: (params: GridRenderCellParams) => <>{params && params.value && params.value.toString().replace('GMT+0000 (Greenwich Mean Time)', '')}</>,
              valueGetter: ({ value }) => value && new Date(value),
            },
            {
              field: 'response', disableColumnMenu: false, headerName: "Response", flex: 1, minWidth: 175, type: 'datetime', 
              renderCell: (params: GridRenderCellParams) => <>{params && params.value && params.value.toString().replace('GMT+0000 (Greenwich Mean Time)', '')}</>,
              valueGetter: ({ value }) => value && new Date(value),
            },
            {
                field: 'result', disableColumnMenu: false, headerName: "Type", flex: 1, minWidth: 50,
                renderCell: (params: GridRenderCellParams) => <>{params.value===true ? 'OK':'FAILED'}</>,
            },
          
            {
                field: 'id',
                sortable: false,
                disableColumnMenu: false,
                headerName: '',
                align: 'right',
                headerAlign: 'right',
                flex: 1,
                minWidth:150,

                renderCell: (params: GridRenderCellParams) => {

                    return <>
                    <DebugButton data={params.row} />
                      
                        

                    </>

                },
            }

        ]}
        autoPageSize
    />
    </Box>

  </>

}


export default DeviceActonsSent;