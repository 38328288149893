import { FormSpy } from "react-final-form";
import { OnChange } from 'react-final-form-listeners';
import { useNavigate } from "react-router-dom";
import doRequest from "../../util/axios";
import CameraInputField from "../../util/fields/camera-field";
import CheckboxInputField from "../../util/fields/checkboxField";
import FileInputField from "../../util/fields/file-field";
import SelectField from "../../util/fields/select-field";
import TextInputField from "../../util/fields/text-field";
import FormContainer from "../../util/form-container";
import FormDividerTitle from "../../util/formDividerTitle";
import ImageDisplay from "../../util/imageDisplay";
import { Button, CardContent, CardMedia, Grid, Typography } from "@mui/material";


const PlantForm = ({ title, buttonText, data }: any) => {
    const navigate = useNavigate();
    return <FormContainer
        data={data}
        title={title}
        requiredFields={[]}
        buttonText={buttonText}
        onSubmit={(async (d: any) => {
            console.log(d)

            if (d._id) {
                const n = await doRequest<any>(`plant/${d._id}`, "PATCH", d)
                console.log(n)
                navigate && navigate('../', { replace: false });
            } else {
                const n = await doRequest<any>('plant', "POST", d)
                console.log(n)
                navigate && navigate('../', { replace: false });
            }

        })}>

        <FormSpy
            subscription={{
                values: true
            }}
        >




            {({ values }) => (<>




                {!values._id && <SelectField name="species" label="Species" requestType="GET" requestUrl="species" required={true} />}

            </>)}
        </FormSpy>
        <TextInputField name="code" label="Code" required={true} />

        <TextInputField name="name" label="Name" />
        <FormSpy
            subscription={{
                values: true,
                // dirtySinceLastSubmit: true,
                // submitting: true,
            }}
        >
            {({ form, values }) => (

                <>
                    <OnChange name="species">
                        {(value) => {
                            // do something
                            form.change('code', value.code + '-')

                        }}
                    </OnChange>
                    <OnChange name="code">
                        {(value) => {
                            // do something
                            form.change('name', value)

                        }}
                    </OnChange>
                    {values.species && <ImageDisplay image={values.species.image} />}

                </>

            )}
        </FormSpy>

        <FormDividerTitle title="Plant Image" />
        <FormSpy
            subscription={{
                values: true
            }}
        >
            {({ values }) => (<>

                <ImageDisplay image={values.image} />

                <FileInputField name="image" label="Image" source="plant.image" />
                <CameraInputField name="image" label="Image" source="plant.image"/>
                <FormDividerTitle title="Plant Images" />
                <Grid item container xs={12} spacing={2} sx={{ paddingTop: 2, }}>
                    {values.images && values.images.map((image: any, index: number) => {
                        let src = '';
                        if (image.displayUrl) {
                            src = `/api/uploads/${image.displayUrl}`;
                        }
                        return <Grid item sm={6} md={4} key={index}>
                            <CardMedia
                                component="img"
                                height="240"
                                image={src}
                                alt="email image"

                            /> <CardContent sx={{ padding: 0, margin: 0 }}>
                                <Typography gutterBottom variant="h5" component="div">

                                    <Button color="secondary" type="button" onClick={ev => {
                                        ev.preventDefault();
                                        // fields.remove(index)
                                    }}>
                                        del
                                    </Button>
                                </Typography>

                            </CardContent>

                        </Grid>



                    })}
                </Grid>
            </>)}
        </FormSpy>


        <TextInputField name="notes" label="Notes" ml={true} />
        <CheckboxInputField name="fromSeed" label="From Seed?" />
        <CheckboxInputField name="fromCutting" label="From Cutting?" />

        <SelectField name="parentPlant" label="Parent Plant" requestType="GET" requestUrl="plant" />
        <SelectField name="area" label="Area" requestType="GET" requestUrl="area" />
        {/* <DateInputField name="bornDate" label="Born / Cut / Seeded / Purchased Date" /> */}
    </FormContainer>
}


export default PlantForm;