import { Route, Routes } from "react-router-dom";
import Device from "./observation";
import DeviceNew from "./observation-new";
import Devices from "./observations";
import SecureRoute from "../../util/secureRoute";



const ObservationRoutes = () => {

    return <Routes>
        <Route path="/" element={<SecureRoute title="Devices"><Devices /></SecureRoute>} />
        <Route path="/:id" element={<SecureRoute title="Area"><Device /></SecureRoute>} />
        <Route path="/new" element={<SecureRoute title="Area"><DeviceNew /></SecureRoute>} />
    </Routes>
}


export default ObservationRoutes;