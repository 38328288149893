import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

interface iProps{
    name:string,
    label:string,
    required:boolean
}


const PsText = ({name, label}:iProps)=>{
    const { control} = useFormContext();

 
    return <>  <Controller
    name={name}
    control={control}
    rules={{required:true}}
    render={({ field:{onChange, value}, fieldState }) => (
    
    
    <TextField
    fullWidth
    id="filled-error"
    label={label}
    value={value}
    error={(fieldState.error ? true: false)}
    onChange={onChange}
    variant="outlined"
  />)} />
  </>
}
export default PsText