
import doRequest from "../../../util/axios";
import UserForm from "./user-form";
const UserNew = () => {

    return <UserForm onSubmit={(async (d: any) => {
        const n = await doRequest<any>('user', "POST", d)
        console.log(n)
    })} data={{}} />
}


export default UserNew