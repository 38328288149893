export enum DataSource{
    Plant = 'plant',

    Area = 'area',

    Device = 'device',

    Sensor = 'sensor',
    
}


export enum SystemCheckers{
    Eq='===',
    LessEq='<=',
    MoreEq= '>=',
    NotEq= '!=='
    
}