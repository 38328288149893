import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import doRequest from "../../util/axios";
import SensorChip from "../../util/sensor-chip";
import DebugButton from "../../util/debugButton";
import { iDevice } from "../../interfaces/iDevice";
import DeviceUpdateForm from "./device-edit-form";
import LineChart from "../../util/lineChart";
import { useNotify } from "../../util/notify";
import DeviceSettings from "./device-settings";
import DeviceActonsSent from "./device-actionsSent";

import Notes from "../../util/notes";
import FormHolder from "../../util/form-holder";
import DisplayDuration from "../../util/display-duration";
import { socket } from "../../util/context";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { updateDevice } from "../../store/devices-slice";
import DisplayDate from "../../util/display-date";









const Device = () => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const { displayNote } = useNotify()
    const [editDevice, setEditDevice] = useState<iDevice>();
    // const [tabsInfo, setTabsInfo] = useState<any[]>();

    const device = useAppSelector(state =>
    state.devices.devices.find(post => post._id === params.id)
    )
    useEffect(()=>{
        if(device){
        const response:any = {...device}
        if (response.plant) {
            response.plant = response.plant._id;
        }
        if (response.area) {
            response.area = response.area._id;
        }
        setEditDevice(response)
        //setLoading(false)
    } else {
        socket.emit('sendMeDevice', params.id)
    }
    }, [device])
   
 
 
    useEffect(() => {
        const onDevice = async (payload: iDevice) => {
            console.log("onDevice", payload)
          dispatch(updateDevice(payload))

        }
        // BAD: this ties the state of the UI with the time of reception of the
        // 'foo' events

        socket.on('device', onDevice);
        socket.emit('subscribeDevices')
        return () => {
            socket.emit('unSubscribeDevices')
            socket.off('device', onDevice);
        };
    }, [])



    const doCommand = async (action: string) => {
        if (device && window.confirm("Are you sure?")) {
            const response = await doRequest<any>(`device/doCmd`, 'POST', { deviceId: device._id, action })
            console.log("static", response)
        }
    }

    if (!device) {
        return <CircularProgress />
    }

    return <><Grid container spacing={2}>
        <Grid item sm={12}>
            <Typography variant="h4">{device && device.name} {device && device.firmwareVersion} [{device && device._id}]

                {/* <Button onClick={() => {
                    socket.emit('subscribeDevices', 'oi')
                }}>dsdsd</Button> */}

            </Typography>

        </Grid>
        <Grid item md={12} lg={6}>
            <Typography variant="h6">Device Details</Typography>
            <Box sx={{ textAlign: 'center' }}>
                Last Connected : {device && device.lastConnected && <><DisplayDate date={device.lastConnected} /> - <DisplayDuration from={device.lastConnected} /></>}<br />
                Last Cold Boot : {device && device.lastStartedUp && <><DisplayDate date={device.lastStartedUp} /> -  <DisplayDuration from={device.lastStartedUp} /></>}<br />
                Last Boot : {device && device.lastBootUp && <><DisplayDate date={device.lastBootUp} /> - <DisplayDuration from={device.lastBootUp} /></>}<br />
                {device && device.sensors.map((d: any, di: number) => {
                    return <SensorChip key={di} d={d} />
                })}

            </Box>

            {editDevice && <FormHolder data={editDevice} onSubmited={async (dataD: any) => {
                displayNote && displayNote('updated device', 'success')
                await doRequest<iDevice>(`device/${dataD._id}`, "PATCH", dataD)
             //   await Go();
            }}>

                <DeviceUpdateForm />
            </FormHolder>}
            <DebugButton data={device} />
        </Grid>
        <Grid item md={12} lg={6}>
            <Typography variant="h6">Device Functions</Typography>
            {device && device.actions && device.actions.map((action, index) => {

                if (action === 'SET_SETTING') {
                    return <></>;
                } else {
                    return <Button key={index} onClick={() => { doCommand(action) }} variant="outlined" color="error">
                        {action}
                    </Button>
                }

            })}
            {device && device.settings && <DeviceSettings settings={device.settings} deviceId={device._id} />}
            {device && <DeviceActonsSent actions={device.actionsSent} />}
            {device && <Notes name="Device" query={{ device: device._id, field: 'note' }} />}
        </Grid>
        <Grid item md={12} lg={6}>

            {device && <LineChart name="Signal" query={{ device: device._id, field: 'wifi_db' }} measurement="db" lowerBoundry={-80} upperBoundry={-50} />}
        </Grid>
        <Grid item md={12} lg={6}>
            {device && <LineChart name="Memory" query={{ device: device._id, field: 'freememory' }} measurement="bits" />}
        </Grid>
        <Grid item md={12} lg={6}>
            {device && <LineChart name="Battery" query={{ device: device._id, field: 'volts' }} measurement="v" />}

        </Grid>
    </Grid>
    </>

}


export default Device