
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import { iToDo } from '../interfaces/iToDo';
import { SxProps } from '@mui/material';



interface iTodoLogoChip {
    code: string;
    todo?: iToDo;
}

export const TodoLogoChip = ({ code, todo }: iTodoLogoChip) => {
 //   console.log(code)

    switch (code) {
        case 'water.plant': 
            const sx: SxProps = {color:'primary'}
            if (todo && todo.startTriggerValue) {
                if(todo.startTriggerValue === 1){
                    //urgent
                    sx.color = "red"
                }
                if(todo.startTriggerValue === 2){
                    //urgent
                    sx.color = "orange"
                }
            }
            return <WaterDropIcon sx={{...sx}} />
        case 'feed.plant':
            return <LunchDiningIcon />
        case 'repot.plant':
            return <AddCircleOutlineIcon />
        case 'complete.plantBasic.observation':
            return <AssignmentIcon />
        case 'complete.plantDetailed.observation':
            return <AssignmentIcon />
        case 'complete.areaDetailed.observation':
            return <AssignmentIcon />
    }
    return <>{code}</>
}