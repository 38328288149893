import SpeciesForm from "./species-form";
const SpeciesNew = () => {


    return <SpeciesForm title="New Species" data={{}} buttonText="New Species" />


}


export default SpeciesNew