import { FormSpy } from "react-final-form";
import { useNavigate } from "react-router-dom";
import doRequest from "../../util/axios";

import FileInputField from "../../util/fields/file-field";
import NumberInputField from "../../util/fields/number-field";
import TextInputField from "../../util/fields/text-field";
import FormContainer from "../../util/form-container";
import FormDividerTitle from "../../util/formDividerTitle";
import ImageDisplay from "../../util/imageDisplay";
import CameraInputField from "../../util/fields/camera-field";

const SpeciesForm = ({ data, title, buttonText }: any) => {
    const navigate = useNavigate();
    return <FormContainer
        data={data}
        title={title}
        requiredFields={[]}
        buttonText={buttonText}
        onSubmit={(async (d: any) => {
            console.log(d)

            if (d._id) {
                const n = await doRequest<any>(`species/${d._id}`, "PATCH", d)
                console.log(n)
                navigate && navigate('../', { replace: false });
            } else {
                const n = await doRequest<any>('species', "POST", d)
                console.log(n)
                navigate && navigate('../', { replace: false });
            }

        })}>

        <TextInputField name="name" label="Name" required={true} />
        <TextInputField name="scienceName" label="Science Name" required={true} />
        <TextInputField name="notes" label="Notes" ml={true} />
        <TextInputField name="nativeArea" label="Native Area" />
        <TextInputField name="code" label="Label Code" required={true} />

        <FormSpy
            subscription={{
                values: true
            }}
        >
            {({ values }) => (<>

                <ImageDisplay image={values.image} />
            </>)}
        </FormSpy>

        <FileInputField name="image" label="Image" source="species.image" />
        <CameraInputField name="image" label="Image" source="species.image" />

        <FormDividerTitle title="Temp" />
        <NumberInputField name="tempLow" label="Low" unitsText="Deg C" />
        <NumberInputField name="tempHigh" label="High" unitsText="Deg C" />

        <FormDividerTitle title="Air Humidity" />
        <NumberInputField name="airHumidityLow" label="Low" unitsText="%" />
        <NumberInputField name="airHumidityHigh" label="High" unitsText="%" />

        <FormDividerTitle title="Light" />
        <NumberInputField name="lightLow" label="Low" unitsText="FC" />
        <NumberInputField name="lightHigh" label="High" unitsText="FC" />
        <NumberInputField name="hoursLight" label="Hours" unitsText="hours" />

        <FormDividerTitle title="Watering" />
        <NumberInputField name="wateringIntervalWinter" label="Interval Winter" unitsText="in weeks" />
        <NumberInputField name="wateringIntervalSummer" label="Interval Summer" unitsText="in weeks" />

        <FormDividerTitle title="Fertilizer" />
        <TextInputField name="fertilizerType" label="Type" />
        <TextInputField name="fertilizerNpk" label="NPK Ratio" unitsText="NN-PP-KK" />
        <TextInputField name="fertilizerDetails" ml={true} label="Details" />
        <NumberInputField name="fertilizerIntervalWinter" label="Interval Winter" unitsText="in weeks" />
        <NumberInputField name="fertilizerIntervalSummer" label="Interval Summer" unitsText="in weeks" />

        <FormDividerTitle title="Notes" />

        <TextInputField name="tempNotes" label="Temp" ml={true} />
        <TextInputField name="soilNotes" label="Soil" ml={true} />
        <TextInputField name="fertilizerNotes" label="Fertilizer" ml={true} />
        <TextInputField name="wateringNotes" label="Water" ml={true} />
    </FormContainer>
}


export default SpeciesForm;