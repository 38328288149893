
import { useState } from "react";
import { Form } from "react-final-form";


import DebugButton from "./debugButton";

import InputIcon from '@mui/icons-material/Input';
import { Box, Button, Grid, Typography } from "@mui/material";
import arrayMutators from 'final-form-arrays';

interface iForm {
    title?: string;
    data?: any;
    requiredFields: string[];
    buttonText: string;
    onSubmit: (data: any) => Promise<void>;
    additionalButtons?: React.ReactNode;
    icon?: React.ReactNode;
    children: React.ReactNode;
    validateOverride?: (data: any) => any
}


function FormContainer<TypeA>({ title, buttonText, onSubmit, validateOverride, data, children, additionalButtons, icon }: iForm) {
    const [loading, setLoading] = useState<boolean>(false)
    return <Box sx={
        {
            border: 1,
            borderColor: 'grey.300',
            borderRadius: 1,
            padding: 1
        }}>
        {title && <Typography variant='h4' sx={{ padding: 1 }}>{title}</Typography>}
        <Form<TypeA>
            initialValues={data}
            mutators={{
                // potentially other mutators could be merged here
                ...arrayMutators
            }}
            subscription={{ values: true, pristine: true, invalid: true, dirtySinceLastSubmit: true }}
            onSubmit={async (d: any) => {
                setLoading(true)
                try {
                    await onSubmit(d)
                    setLoading(false)
                } catch (ex) {
                    setLoading(false)
                }

            }}

            validate={(values) => {
                let errors: any = {};
                if (validateOverride !== undefined) {
                    errors = validateOverride(values)
                }
                return errors;
            }}
            render={({
                handleSubmit,
                pristine,
                values,
                invalid,
                dirtySinceLastSubmit,
            }) => (
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    noValidate
                >

                    <Grid
                        container
                        spacing={2}
                        justifyContent="space-between"
                        alignItems="center">

                        {children}

                        <Grid item xs={12} sm={12} md={12}>
                            <DebugButton data={values} alwaysDisplay={true} />
                            {additionalButtons && additionalButtons}
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={
                                    pristine || (invalid && !dirtySinceLastSubmit) || loading
                                }
                                type="submit">
                                {buttonText}
                                <span style={{ marginLeft: "2px" }}>{icon ? icon : <InputIcon />}</span>
                            </Button>
                        </Grid>


                    </Grid>
                </form>
            )}
        />
    </Box>
}


export default FormContainer
