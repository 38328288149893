
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useAuth } from "./context";
import { iInsecureRoute } from "./insecureRoute";
import PageWrapper from "./pageWrapper";


interface iSecureRoute extends iInsecureRoute {

}
const SecureRoute = ({ title, children }: iSecureRoute) => {
    const {auth} = useAuth();
    console.log(auth)

    if (auth) {
        return <PageWrapper title={title}>{children}</PageWrapper>
    } else {
        return <PageWrapper title={"Access Denied"}>
            <Typography textAlign="center">Access Denied
                <br />
                <Link to="/login">Login</Link>

            </Typography></PageWrapper>
    }
}

export default SecureRoute