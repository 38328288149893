import { Route, Routes } from "react-router-dom";
import Plant from "./plant";
import PlantNew from "./plant-new";

import Plants from "./plants";
import SecureRoute from "../../util/secureRoute";


const PlantsRoutes = () => {

    return <Routes>
        <Route path="/" element={<SecureRoute title="Plants"><Plants /></SecureRoute>} />
        <Route path="/:id" element={<SecureRoute title="Job"><Plant /></SecureRoute>} />
        <Route path="/new" element={<SecureRoute title="Job"><PlantNew /></SecureRoute>} />
    </Routes>
}


export default PlantsRoutes;