import { Login, Logout } from '@mui/icons-material';
import GrassIcon from '@mui/icons-material/Grass';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from "./context";
import { NotifyDisplay } from './notify';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
const pages = ['Plants', 'Species', 'Devices', 'Plant Areas', 'Sensors', 'Observations'];
const links = ['/plants', '/species', '/devices', '/areas', '/sensors', '/observations']
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Nav = () => {
    const {auth, signOut} = useAuth();

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    return <><AppBar
        elevation={0}
        position="fixed"
        style={{
            zIndex: 999,
            display: "flex",
            alignItems: "center",
        }}>
        <Container maxWidth="xl">
            <Toolbar disableGutters>
                <Link to="/">
                    <Typography
                        variant="h6"
                        noWrap
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color:'white',
                            textDecoration: 'none',
                        }}
                    >
                        <GrassIcon />
                    </Typography>
                </Link>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        
                    >
                        <MenuIcon sx={{color:"white"}}/>
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                    >
                        {pages.map((page, iPage) => {
                            const link = links[iPage];


                            return <MenuItem key={page} onClick={handleCloseNavMenu}>
                                <Link to={link}>
                                    <Typography textAlign="center"  sx={{color:'white'}}>{page}</Typography></Link>
                            </MenuItem>
                        })}
                    </Menu>
                </Box>
                <Link to="/">
                    <Typography
                        variant="h5"
                        noWrap
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                 
                            textDecoration: 'none',
                        }}
                    >
                        <GrassIcon sx={{color:"white"}}/>
                    </Typography>
                </Link> 
                
               {auth && auth.isAdmin === true &&  <Link to="/admin">
                    <Typography
                        variant="h5"
                        noWrap
                        sx={{
                            mr: 2,
                            display:'flex',
                          //  display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            
                            textDecoration: 'none',
                        }}
                    >
                        <AdminPanelSettingsIcon sx={{color:"white"}}/>
                    </Typography>
                </Link>}
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    {pages.map((page, iPage) => {
                        const link = links[iPage];


                        return <Link key={iPage} to={link}><Button
                            key={page}
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                            {page}
                        </Button></Link>
                    })}
                </Box>

                {auth?.id ? <><Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar alt={`${auth && auth.name}`} src="/static/images/avatar/2.jpg" />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        <MenuItem>

                            <Typography textAlign="center">
                                {auth && auth.name}
                            </Typography>


                        </MenuItem>

                        <MenuItem onClick={handleCloseUserMenu}>

                            <Link color='inherit' to={`/profile`}>
                                <Typography textAlign="center">
                                    Profile
                                </Typography>

                            </Link>

                        </MenuItem>

                        <MenuItem onClick={handleCloseUserMenu}>
                            <IconButton color='inherit' onClick={() => {
                                signOut && signOut();
                            }}>
                                <Typography textAlign="center">
                                    <Logout
                                        style={{
                                            transform: "scale(1.4)",
                                        }}
                                    />
                                </Typography>


                            </IconButton>

                        </MenuItem>


                    </Menu>

                </Box>
                </> : <>
                    <Link to="/login">
                        <Typography textAlign="center">
                            <Login
                                style={{
                                    transform: "scale(1.4)",
                                }}
                            />
                        </Typography>


                    </Link>

                </>}
            </Toolbar>
        </Container>
    </AppBar>

        <NotifyDisplay />
    </>



}

export default Nav;