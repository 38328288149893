
import { Route, Routes } from "react-router-dom";
import Sensor from "./sensor";
import SensorNew from "./sensor-new";
import Sensors from "./sensors";
import SecureRoute from "../../util/secureRoute";



const SensorRoutes = () => {

    return <Routes>
        <Route path="/" element={<SecureRoute title="Devices"><Sensors /></SecureRoute>} />
        <Route path="/:id" element={<SecureRoute title="Sensor"><Sensor /></SecureRoute>} />
        <Route path="/new" element={<SecureRoute title="Sensor"><SensorNew /></SecureRoute>} />
    </Routes>
}


export default SensorRoutes;