import { FormSpy } from "react-final-form";
import { useNavigate } from "react-router-dom";
import doRequest from "../../util/axios";
import CheckboxInputField from "../../util/fields/checkboxField";
import FileInputField from "../../util/fields/file-field";
import TextInputField from "../../util/fields/text-field";
import FormContainer from "../../util/form-container";
import ImageDisplay from "../../util/imageDisplay";

const AreaForm = ({ title, buttonText, data }: any) => {
    const navigate = useNavigate();
    return <FormContainer
        data={data}
        title={title}
        requiredFields={[]}
        buttonText={buttonText}
        onSubmit={(async (d: any) => {
            console.log(d)

            if (d._id) {
                const n = await doRequest<any>(`area/${d._id}`, "PATCH", d)
                console.log(n)
                navigate && navigate('../', { replace: false });
            } else {
                const n = await doRequest<any>('area', "POST", d)
                console.log(n)
                navigate && navigate('../', { replace: false });
            }

        })}>


        <TextInputField name="code" label="Code" required={true} />

        <TextInputField name="name" label="Name" required={true} />
        <TextInputField name="weatherLocation" label="Weather Location" required={true} />
        <CheckboxInputField name="inWindow" label="In Window?" />
        <CheckboxInputField name="isRoom" label="In Room?" />
        <CheckboxInputField name="isOutdoor" label="In Outdoor?" />
        <CheckboxInputField name="isEnclosure" label="In Enclosure?" />
        <FormSpy
            subscription={{
                values: true
            }}
        >
            {({ values }) => (<>

                <ImageDisplay image={values.image} />
            </>)}</FormSpy>
        <FileInputField name="image" label="Image" source="area.image" />

    </FormContainer>
}


export default AreaForm;