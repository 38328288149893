
import ObservationForm from "./observation-form";
const ObservationNew = () => {

    return <ObservationForm onSubmit={(async () => {
       
        
    })} data={{}} />
}


export default ObservationNew