
import { useNavigate } from "react-router-dom";
import FormContainer from "../../../util/form-container";
import doRequest from "../../../util/axios";
import TextInputField from "../../../util/fields/text-field";
import CheckboxInputField from "../../../util/fields/checkboxField";

const UserForm = ({ title, buttonText, data }: any) => {
    const navigate = useNavigate();
    return <FormContainer
        data={data}
        title={title}
        requiredFields={[]}
        buttonText={buttonText}
        onSubmit={(async (d: any) => {
            console.log(d)

            if (d._id) {
                const n = await doRequest<any>(`user/${d._id}`, "PATCH", d)
                console.log(n)
                navigate && navigate('../', { replace: false });
            } else {
                const n = await doRequest<any>('user', "POST", d)
                console.log(n)
                navigate && navigate('../', { replace: false });
            }

        })}>

        <TextInputField name="name" label="Name" required={true} />

        <TextInputField name="emailAddress" label="Email Address" required={true} />
        <TextInputField name="context" label="context " required={true} />
        <TextInputField name="password" label="Password" required={true} />


        <CheckboxInputField name="isAdmin" label="Admin?" />
       
    </FormContainer>
}


export default UserForm;