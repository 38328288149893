import { Box, Typography } from "@mui/material";

import { iSystemSchemaQuestion } from "../../interfaces/iSystemSchema";

import {  useFormContext } from "react-hook-form";
import RateingField from "./rateing";
import NumberField from "./number";
import CheckField from "./checkbox";
import ImagesField from "./images";
import TTextField from "./text";
import DebugButton from "../debugButton";
import { Fragment } from "react";

interface iObservationQuestionsForm{

}
const ObservationQuestionsSubForm = ({}:iObservationQuestionsForm) =>{
 const methods = useFormContext();
 const { watch, setValue } = methods;
 const schemaData = watch('schemaData');
 const watcheddata = watch('data');
 return <>
 
 {schemaData && schemaData.questions && schemaData.questions.map((q:iSystemSchemaQuestion, index:number) => {


let value = watcheddata[q.id]
const updateField = `${q.id}`

const displayIf = ()=>{
    let ret = true;
    if(q.displayIfField && q.displayIfModify && q.displayIfValue){
        ret = false;

        const ifValue = watcheddata[q.displayIfField]

        console.log(q, ifValue)
        if(ifValue!==undefined){
            switch(q.displayIfModify){
                case '>=':
                    if(ifValue >= q.displayIfValue){
                        ret=true;
                    }

                break;
                case '<=':
                    if(ifValue <= q.displayIfValue){
                        ret=true;
                    }

                break;
                case '===':
                    if(ifValue === q.displayIfValue){
                        ret=true;
                    }

                break;
            }
        }

    }
    return ret;
}


return <Fragment key={index}>
    
    {displayIf() === true && <Box>
        <Typography variant="subtitle1">{q.name}</Typography>
        <DebugButton data={q} />

        {q.type && q.type.id === 'rateing' && <RateingField value={value} update={(up) => {
            const dataa: any = { ...watcheddata }
            dataa[updateField] = up;
            setValue("data", dataa)
        }} />}
        {q.type && q.type.id === 'number' && <NumberField value={value} update={(up) => {
            const dataa: any = { ...watcheddata }
            dataa[updateField] = up;
            setValue("data", dataa)
        }} />}
        {q.type && q.type.id === 'checkbox' && <CheckField value={value} update={(up) => {
            const dataa: any = { ...watcheddata }
            dataa[updateField] = up;
            setValue("data", dataa)
        }} />}
        {q.type && q.type.id === 'photos' && <ImagesField source={`${q.targetObject.toLowerCase()}.${q.targetField.toLowerCase()}`} value={value} update={(up) => {
            const dataa: any = { ...watcheddata }
            dataa[updateField] = up;
            setValue("data", dataa)
        }} />}
        {q.type && q.type.id === 'text' && <TTextField value={value} update={(up) => {
            const dataa: any = { ...watcheddata }
            dataa[updateField] = up;
            setValue("data", dataa)
        }} />}
    </Box>}
    </Fragment>

})}
 </>
}



export default ObservationQuestionsSubForm
