import { Alert, Box, Button, CircularProgress } from "@mui/material";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useState } from "react";
import DebugButton from "./debugButton";

interface iFormHolder {
  buttonText?: string;
  data: any;
  onSubmited: (data: any) => Promise<any>;
  children: JSX.Element;
}
const FormHolder = ({ data, onSubmited, children, buttonText = 'Submit' }: iFormHolder) => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  //  const methods = useForm<any>({ defaultValues: {...device } })
  const methods = useForm({
    defaultValues: { ...data },
  })
  const onSubmit: SubmitHandler<any> = async (dataD) => {
    try {
      setError('');
      setLoading(true);
      await onSubmited(dataD)

      setLoading(false)
    } catch (ex: any) {
      setError(ex)
      setLoading(false)
    }
  }


  const { handleSubmit, getValues } = methods;
  // const schemaData = watch('schemaData');

  if (loading === true) {
    return <Box><CircularProgress /></Box>
  }
  return <>




    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DebugButton data={getValues()} />
        {error !== '' && <Alert color="error">{error}</Alert>}
        {children}


        <Box>

          <Button variant="outlined" color="secondary"  fullWidth type="submit">{buttonText}</Button>
        </Box>
      </form>
    </FormProvider>

  </>

}


export default FormHolder;