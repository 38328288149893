import { Box, Grid, useTheme } from "@mui/material";
import { useDropzone } from 'react-dropzone';
import { Field, FormSpy } from "react-final-form";
import doRequest from "../axios";
import { useFormContext } from "react-hook-form";
interface iTextInput {
    name: string;
    label: string;
    source: string;
    overrideOnChange?: (data: any) => void
}
//https://react-dropzone.js.org/





export const FileInputFieldHook = ({ name, label, source, overrideOnChange }: iTextInput) => {
    const theme = useTheme();
    console.log("name",name)
    //  const ml = multiline;
    const { watch, register } = useFormContext() // retrieve all hook methods

    const OuterDropZone = (props: any) => {

        const value = watch(name)

        const { getRootProps } = useDropzone({
            // Note how this callback is never invoked if drop occurs on the inner dropzone
            onDrop: files => {
                console.log(files);

                const Go = async () => {
                    try {
                        const file = await doRequest<any>(`image?source=${source}`, 'FILE', files[0]);
                        console.log(file)

                        let save: any = { ...value, ...file };

                        props.onChangeFinished && props.onChangeFinished(save);
                    } catch (error) {
                        console.log(error)
                    }

                }
                Go()

            }
        });
        const boxSX = {
            marginTop: 1,
            marginBottom: 2,
            marginLeft: 2,
            marginRight: 2,
            textAlign: 'center',
            "&:hover": {
                "background-color": theme.palette.primary.light,
            },
        };

        return <Box sx={{
            width: "100%", border: 1, borderColor: 'secondary.main', marginBottom: 2,
            borderRadius: 1,
        }}  {...register(name)}  {...getRootProps({ className: 'dropzone' })}>
            <Box className="middleHolder" sx={boxSX}>
                <div className="middle">Click or Drag and drop files to {label}</div>
            </Box>
        </Box>
    }

    const dropped = (props: any) => {
        //        console.log(props)
        if (overrideOnChange !== undefined) {
            overrideOnChange(props)
        } else {
            //  onChange(props)
        }
    }
    return <Grid item xs={12}>

        <OuterDropZone onChangeFinished={dropped} />


    </Grid>
}



const FileInputField = ({ name, label, overrideOnChange, source }: iTextInput) => {
    const theme = useTheme();
    //  const ml = multiline;

    return <Grid item xs={12}>
        <FormSpy
            subscription={{

            }}
        >
            {({ }) => (<>


                <Field name={name}>
                    {({ input: { onChange, value } }) => {
                        const OuterDropZone = (props: any) => {



                            const { getRootProps } = useDropzone({
                                // Note how this callback is never invoked if drop occurs on the inner dropzone
                                onDrop: files => {
                                    console.log(files);

                                    const Go = async () => {
                                        try {
                                            const file = await doRequest<any>(`image?source=${source}`, 'FILE', files[0]);
                                            console.log(file)

                                            let save: any = { ...value, ...file };

                                            props.onChangeFinished && props.onChangeFinished(save);
                                        } catch (error) {
                                            console.log(error)
                                        }

                                    }
                                    Go()

                                }
                            });
                            const boxSX = {
                                marginTop: 1,
                                marginBottom: 2,
                                marginLeft: 2,
                                marginRight: 2,
                                textAlign: 'center',
                                "&:hover": {
                                    "background-color": theme.palette.primary.light,
                                },
                            };

                            return <Box sx={{
                                width: "100%", border: 1, borderColor: 'secondary.main', marginBottom: 2,
                                borderRadius: 1,
                            }} {...getRootProps({ className: 'dropzone' })}>
                                <Box className="middleHolder" sx={boxSX}>
                                    <div className="middle">Click or Drag and drop files to {label}</div>
                                </Box>
                            </Box>
                        }

                        const dropped = (props: any) => {
                            //        console.log(props)
                            if (overrideOnChange !== undefined) {
                                overrideOnChange(props)
                            } else {
                                onChange(props)
                            }
                        }

                        return <OuterDropZone onChangeFinished={dropped} />

                    }}
                </Field>
            </>
            )}
        </FormSpy>
    </Grid>
}


export default FileInputField;