import { createSlice, PayloadAction } from '@reduxjs/toolkit'
export interface MqqtLogDict {
  [index: string]: any;
}
// Define a type for the slice state
interface AdminSlice {
  mqttLog:MqqtLogDict;
}

// Define the initial state using that type
const initialState: AdminSlice = {

  mqttLog:{}
}

export const adminSlice = createSlice({
  name: 'admin',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearMqttLog:(state) =>{
      state.mqttLog = {}
    },
    
    newMqttLog:(state, action:PayloadAction<any>) =>{
      state.mqttLog[action.payload.ts+action.payload.topic] = action.payload
    },
  },
})

export const {clearMqttLog,  newMqttLog} = adminSlice.actions

// Other code such as selectors can use the imported `RootState` type
//export const selectCount = (state: RootState) => state.devices.value

export default adminSlice.reducer