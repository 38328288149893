import { Grid, Typography } from "@mui/material"

const FormDividerTitle = ({ title }: { title: string }) => {
    return <Grid item xs={12}>
        <Typography variant='h4' sx={{ padding: 1 }}>
            {title}
        </Typography>
    </Grid>
}


export default FormDividerTitle