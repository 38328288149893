import { Box, CircularProgress, Divider, IconButton, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { iData } from "../interfaces/iData";
import doRequest from "./axios";
import { serialize } from "./lineChart";

import { Controller, useFormContext } from "react-hook-form";
import DebugButton from "./debugButton";
import DeleteIcon from '@mui/icons-material/Delete';
import FormHolder from "./form-holder";
import DisplayDate from "./display-date";
interface iDisplayNotesProp {
  datas: iData[]
}

const DisplayNotes = ({ datas }: iDisplayNotesProp) => {
  return <>
    {datas.map((data, index) => {

      return <Box key={index}>

        <Typography variant="body2" sx={{ "white-space": "pre-line", pt: 2, pb: 2, textAlign: 'left' }}>
          {data.value}
        </Typography>
        <Divider />
        <IconButton color="error"><DeleteIcon /></IconButton>
        <DisplayDate date={data.created} />
        <DebugButton data={data} />
      </Box>
    })}
  </>
}


interface iNoteFormProp {

}

const NoteForm = ({ }: iNoteFormProp) => {
  const { control } = useFormContext();

  return <>
    <Controller
      name="value"
      control={control}
      render={({ field }) => (
        <TextField {...field} multiline={true} fullWidth label="note" variant="outlined" />
      )}
    />

  </>
}




interface iNotesProp {
  query: any;
  name: string;
}
const Notes = ({ name, query }: iNotesProp) => {
  const [loading, setLoading] = useState(true);

  const mounted = useRef(false)
  const [sourceData, setSourceDatas] = useState<iData[]>([]);
  const Go = async () => {

    setLoading(true)
    const response = await doRequest<any>(`data/?${serialize({ ...query })}`, 'GET', {})
    console.log("static", response)

    setSourceDatas(response)
    setLoading(false)

  }

  useEffect(() => {
    if (mounted.current === false) {
      mounted.current = true;
      Go();
    }
  }, [query])



  return <>
    <Box>
      <Typography variant="h4">{name} Notes</Typography>
      {loading ? <CircularProgress /> : <>
        <DisplayNotes datas={sourceData} />


        <FormHolder buttonText="Create Note" data={{ value: '', ...query }} onSubmited={async (d: any) => {
          setLoading(true);
          await doRequest<any>('data', "POST", d)
          setTimeout(() => Go(), 1000);
        }}>
          <NoteForm />
        </FormHolder>
      </>}

    </Box>

  </>
}


export default Notes;