import { Grid, InputAdornment, TextField } from "@mui/material";
import { Field, FormSpy } from "react-final-form";
import { composeValidators, maxValueValidate, minValueValidate, requiredValidate } from "./validators";

interface iNumberInput {
    name: string;
    label: string;
    type?: 'int' | 'float';
    required?: boolean;
    minValue?: number;
    maxValue?: number;
    unitsText?: string;
}

const NumberInputField = ({ name, label, type = 'int', required = false, unitsText, minValue, maxValue }: iNumberInput) => {

    //  const ml = multiline;

    let fieldProps: any = {
        name,
        parse: (value: any, name: string) => {

            console.log("parse", value, name)
            if (value === '' || value === undefined) {
                return '';
            } else if (!isNaN(value) && value !== null) {
                if (type === 'int') {
                    return parseInt(value);
                } else {
                    return parseFloat(value)
                }
            }
        },
        format:
            (value: any, name: string) => {
                console.log("format", value, name)
                if (value === '' || value === undefined) {
                    return '';
                } else if (!isNaN(value) && value !== null) {
                    if (type === 'int') {
                        return parseInt(value);
                    } else {
                        return parseFloat(value)
                    }
                }
                //  else return ''

            }
    }
    const d: any[] = [];

    if (required === true) {
        d.push(requiredValidate);
    }

    if (minValue !== undefined) {
        d.push(minValueValidate(minValue));
    }

    if (maxValue !== undefined) {
        d.push(maxValueValidate(maxValue));
    }

    if (d.length > 0) {
        fieldProps.validate = composeValidators(d)
    }

    let textFiledProps: any = {
        InputProps: {},
        autoComplete: 'off',
        name,
        label,
        fullWidth: true,
        type,
        required,
        variant: "outlined",
        color: "secondary",
    };

    if (unitsText) {
        textFiledProps.InputProps = { endAdornment: <InputAdornment sx={{ textColor: '500:grey', fontSize: '0.4em' }} position="end" >{unitsText}</InputAdornment> };
    }

    if (type === 'float') {
        textFiledProps.InputProps.step = "any"
    }
    return <Grid item xs={12}>
        <FormSpy
            subscription={{

                dirtySinceLastSubmit: true,
                submitting: true,
            }}
        >
            {({ dirtySinceLastSubmit, submitting }) => (
                <Field

                    {...fieldProps}

                >
                    {({ input, meta }) => {
                        return <>
                            <TextField
                                {...input}
                                {...textFiledProps}
                                error={
                                    (meta.error && meta.touched) ||
                                    (!!meta.submitError &&
                                        !dirtySinceLastSubmit &&
                                        !submitting)
                                }
                                helperText={
                                    meta.error && meta.touched
                                        ? meta.error
                                        : !!meta.submitError &&
                                            !dirtySinceLastSubmit &&
                                            !submitting
                                            ? meta.submitError
                                            : ""
                                }
                            />


                        </>
                    }}
                </Field>
            )}
        </FormSpy>
    </Grid >
}


export default NumberInputField;