import { Switch } from "@mui/material"
import { useState } from "react"

interface iCheckFieldProps{
    value: any
    update:(value:any)=>void
}
const CheckField = ({value, update}:iCheckFieldProps)=>{
    const [thisValue, setThisValue] = useState(()=>{
        if(value){
        return value
        } else {
            return false
        }
    })
    return     <Switch
    
    value={thisValue}
    onChange={(event, newValue) => {
        event
        setThisValue(newValue);
        update(newValue);
    }}
/>
}

export default CheckField;