import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import doRequest from '../../util/axios';
import TextInputField from '../../util/fields/text-field';
import FormContainer from '../../util/form-container';
interface dummyResponse {
    ok: boolean,
    d: any
}
const ResetPassword = () => {
    let params = useParams();

    const [d, setD] = useState<boolean>(false)

    return <>
        {d === false ? <FormContainer
            data={{ code: params.code }}
            onSubmit={async ({ code, password }) => {
                //    await doLogin(email, password, rememberMe)
                console.log(code, password)
                const response = await doRequest<dummyResponse>('auth/reset-password', "POST", { code, password })
                console.log("reset-password", response)
                if (response && response.ok === true) {
                    setD(true);
                } else {
                    if (response && response.d) {
                        alert(response.d);
                    } else {
                        alert("Something was not right there, please try again.")
                    }
                }
            }}

            requiredFields={['password', 'password2']}
            buttonText="Set Account Password"
            additionalButtons={<Button fullWidth><Link to="/login">Login</Link></Button>}
        >
            <TextInputField name="password" label="Password" type="password" />
            <TextInputField name="password2" label="Re-Enter Password" type="password" />
        </FormContainer> : <Typography>Your password has been updated now please <Button fullWidth><Link to="/login">Login</Link></Button></Typography>
        }
    </>
}


export default ResetPassword