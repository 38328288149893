import dayjs, { Dayjs } from 'dayjs';

interface iDisplayDate {
    date: Date | number | string;
    outputFormat?: string;
}
const DisplayDate = ({ date, outputFormat = 'M/D/YYYY h:mm:ss A' }: iDisplayDate) => {
    if (date !== undefined) {

        let d: Dayjs;
        d = dayjs(date)



        return <>
            {d && d.format(outputFormat)}
        </>
    } else {
        return <></>
    }
}


export default DisplayDate;