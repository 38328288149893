import { SxProps } from "@mui/material";

export const popupStyle:SxProps = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: {md:"70%",xs:"95%"},
    width: {md:"70%",xs:"95%"},
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow: "scroll",
    mr:2,
    p:{sm:5, sx:1}
};

export const popupStyleFullscreeen:SxProps = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor:'black',
    height: "100%",
    width: "100%",
    border: '2px solid #000',
    boxShadow: 24,
    overflow: "scroll",
    mr:2,
    p:{sm:5, sx:1}
};
