import { Route, Routes } from "react-router-dom";
import AreaRoutes from "./areas";
import AuthRoutes from "./auth";
import DashRoutes from "./dashboard";
import DeviceRoutes from "./device";
import ObservationRoutes from "./observation";
import PlantsRoutes from "./plants";
import SensorRoutes from "./sensor";
import SpeciesRoutes from "./species";
import AdminRoutes from "./admin";





const IndexRoutes = () => {
    return <Routes>
        <Route path="/*" element={<DashRoutes />} />
        <Route path="admin/*" element={<AdminRoutes />} />
        <Route path="login/*" element={<AuthRoutes />} />
        <Route path="plants/*" element={<PlantsRoutes />} />
        <Route path="species/*" element={<SpeciesRoutes />} />
        <Route path="areas/*" element={<AreaRoutes />} />
        <Route path="devices/*" element={<DeviceRoutes />} />
        <Route path="observations/*" element={<ObservationRoutes />} />
        <Route path="sensors/*" element={<SensorRoutes />} />
    </Routes>
}


export default IndexRoutes