import { Avatar, Box, Button, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import doRequest from "../../util/axios";
import PlantForm from "./plant-form";
import EditIcon from '@mui/icons-material/Edit';
import TodoChip from "../../util/todo-chip";
import { iPlant } from "../../interfaces/iPlant";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import DebugButton from "../../util/debugButton";
import { iToDo } from "../../interfaces/iToDo";
import ImageDisplay from "../../util/imageDisplay";
import SensorChip from "../../util/sensor-chip";
import LineChart from "../../util/lineChart";
import Notes from "../../util/notes";


const Plant = () => {
    const params = useParams();
    const mounted = useRef(false)
    const navigate = useNavigate();
    const [plant, setThing] = useState<iPlant>();
    const [toDo, setTodo] = useState<iToDo[]>();
    // const [tabsInfo, setTabsInfo] = useState<any[]>();
    const [showEdit, setShowEdit] = useState(false);
    const Go = async () => {
        const response = await doRequest<iPlant>(`plant/${params.id}`, 'GET', {})
        console.log("static", response)
        if (response) {
            setThing(response)
            const obs = await doRequest<iToDo[]>(`to-do?plant=${params.id}`, 'GET', {})
            console.log("setObs", obs)

            setTodo(obs)

        }

    }
    useEffect(() => {
        if (mounted.current === false) {
            mounted.current = true;
            Go();
        }
    }, [params.id])


    if (plant === undefined) {
        return <CircularProgress />
    }
    let src = '';
    if (plant.image && plant.image.displayUrl) {
        src = `/api/uploads/${plant.image.displayUrl}`;
    }

    // let tempWarning: null | boolean = null;
    // if (plant.species && plant.species.tempHigh && plant.species.tempLow) {
    //     if (area._currentTemp) {
    //         if (area._currentTemp >= plant.species.tempLow && area._currentTemp <= plant.species.tempHigh) {
    //             tempWarning = false;
    //         } else {
    //             tempWarning = true;
    //         }
    //     }
    // }
    // let aitHumidityWarning: null | boolean = null;
    // if (plant.species && plant.species.airHumidityLow && plant.species.airHumidityHigh) {
    //     if (area._currentAirHumidity) {
    //         if (area._currentAirHumidity >= plant.species.airHumidityLow && area._currentAirHumidity <= plant.species.airHumidityHigh) {
    //             aitHumidityWarning = false;
    //         } else {
    //             aitHumidityWarning = true;
    //         }
    //     }
    // }


    return <>

        <Grid container spacing={2}>
            <Grid item sm={12}>
                <Typography variant="h4">{plant.name} <DebugButton data={plant} /></Typography>
            </Grid>

            {showEdit == true ? <Grid item sm={12}>
                <IconButton onClick={() => setShowEdit(!showEdit)}><EditIcon /> </IconButton>
                <PlantForm title="Edit Plant" data={plant} buttonText="Save" />
            </Grid> : <Grid item container sm={12} sx={{ textAlign: 'center' }}>
                <Grid item xl={6} sm={12} xs={12} sx={{ textAlign: "center" }}>

                    <Avatar onClick={() => {
                        navigate(`/plants/${plant._id}`)
                    }} alt="" src={src} sx={{ width: 366, height: 366, m: 'auto' }} />
                    <IconButton onClick={() => setShowEdit(!showEdit)}><EditIcon /> </IconButton>
                    {plant.sensors && plant.sensors.map((d: any, di: number) => {
                        return <SensorChip key={di} d={d} />
                    })}
                    {plant.toDos && plant.toDos.map((d: any, di: number) => {
                        return <TodoChip key={di} d={d} index={di} plant={plant} done={async () => {
                            setTimeout(async () => {
                                Go();
                            }, 500)
                        }} />
                    })}
                    <Box>
                        {plant.species && plant.species.tempHigh && plant.species.tempLow && <Typography>
                            Temp
                            {/* {tempWarning !== null && tempWarning === true && "Warning"}  */}
                            <br />
                            ({plant.species.tempLow} -  {plant.species.tempHigh})
                        </Typography>}


                        {plant.species && plant.species.airHumidityLow && plant.species.airHumidityHigh && <Typography>
                            Air Humidity
                            {/* {aitHumidityWarning !== null && aitHumidityWarning === true && "Warning"} 
                       */}

                            <br />
                            ({plant.species.airHumidityLow} - {plant.species.airHumidityHigh})
                        </Typography>}
                    </Box>
                    <Grid container>
                        {plant.images && plant.images.map((p, i) => {

                            return <Grid key={i} item xs={6} sm={6}>
                                <ImageDisplay image={p} />
                                <DebugButton data={p} />
                            </Grid>
                        })}
                    </Grid>

                    <Grid container>
                        {plant.leafImages && plant.leafImages.map((p, i) => {

                            return <Grid key={i} item xs={6} sm={6} >
                                <ImageDisplay image={p} />
                            </Grid>
                        })}
                    </Grid>
                </Grid>
                <Grid item xl={6} sm={12} xs={12}>
                {plant && <Notes name="Plant" query={{plant:plant._id, field:'note'}} />}

                    </Grid>
                <Grid item xl={6} sm={12} xs={12}>
                    <Typography variant="h4">To Do</Typography>
                    {toDo && <DataGrid
                        getRowId={r => r._id}
                        disableColumnSelector
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'created', sort: 'asc' }],
                            },
                        }}
                        rows={toDo}
                        rowHeight={72}
                        columns={[

                            {
                                field: 'created', disableColumnMenu: false, headerName: "Connected", flex: 1, minWidth: 50, type: 'datetime', valueGetter: ({ value }) => value && new Date(value),
                                renderCell: (params: GridRenderCellParams) => <>{params && params.value && params.value.getDate()}/{params && params.value && params.value.getMonth() + 1}/{params && params.value && params.value.getFullYear()} {params && params.value && params.value.getHours()}:{params && params.value && params.value.getMinutes()}</>
                            },
                            {
                                field: 'type', disableColumnMenu: false, headerName: "Type", flex: 0.7, valueGetter: ({ value }) => value && value.name
                            },

                            {
                                field: 'complete', disableColumnMenu: false, headerName: "Complete", flex: 0.7,

                                renderCell: (params: GridRenderCellParams) => {

                                    return <>
                                        {params.value === true ? 'Done' : 'Todo'}

                                    </>

                                },
                            },

                            {
                                field: '_id',
                                sortable: false,
                                disableColumnMenu: false,
                                headerName: 'Actoins',
                                align: 'right',
                                headerAlign: 'right',
                                flex: 1,
                                //minWidth: 150,
                                renderCell: (params: GridRenderCellParams) => {

                                    return <>

                                        <DebugButton data={params.row} />
                                        <Button variant="outlined" onClick={() => navigate && navigate(`/plants/${params.row._id}`)}><EditIcon /></Button>

                                    </>

                                },
                            }

                        ]}
                        autoPageSize
                    />}
                </Grid>

                <Grid item xl={12} sm={12} xs={12}>
                    <Typography variant="h4">Sensor Data</Typography>
                    {typeof plant.area !== 'string' && <>
                        <LineChart name="Light" query={{ plant: plant._id, field: 'lux' }} measurement="lx"  />

                        <LineChart name="Air Temp" query={{ plant: plant._id, field: 'air-temp' }} measurement="C" lowerBoundry={plant.species.tempLow} upperBoundry={plant.species.tempHigh} />
                        <LineChart name="Air Humid" query={{plant: plant._id, field: 'air-humidity' }} measurement="%" lowerBoundry={plant.species.airHumidityLow} upperBoundry={plant.species.airHumidityHigh} />

                        <LineChart name="Soil Water" query={{ plant: plant._id, field: 'soil-humidity' }} measurement="%" />

                        <LineChart name="Soil Salt" query={{ plant: plant._id, field: 'salt' }} measurement="ec" />
                    </>}
                </Grid>

            </Grid>}
        </Grid ></>
}


export default Plant