import doRequest from "../../util/axios";
import SensorForm from "./sensor-form";
const SensorNew = () => {

    return <SensorForm onSubmit={(async (d: any) => {
        const n = await doRequest<any>('device', "POST", d)
        console.log(n)
    })} data={{}} />
}


export default SensorNew