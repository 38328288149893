import { CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import doRequest from "../../util/axios";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import DebugButton from "../../util/debugButton";
const Sensor = () => {
    const params = useParams();
    const [thing, setThing] = useState<any>();
    const [datas, setDatas] = useState<any[]>([]);

    useEffect(() => {
        const Go = async () => {
            const response = await doRequest<any>(`sensor/${params.id}`, 'GET', {})
            console.log("static", response)
            if (response) {
                setThing(response)


                const response2 = await doRequest<any[]>(`data/?sensor=${params.id}`, 'GET', {})
                console.log("static", response2)
                if (response2) {
                    setDatas(response2)

                }
            }
        }
        Go();
    }, [params.id])


    if (thing === undefined) {
        return <CircularProgress />
    }

    return <Grid container spacing={2}>
     
        <Grid item md={12}>
        <div style={{ height: '500px' }}>
            <DataGrid
                getRowId={r => r._id}
                disableColumnSelector
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'updated', sort: 'asc' }],
                    },
                }}
                rowHeight={52}
                rows={datas}
                columns={[
                    {
                        field: 'created', disableColumnMenu: false, headerName: "Created", type:'dateTime', flex: 0.7, minWidth: 100, valueGetter: ({ value }) => value && new Date(value),
                        renderCell: (params: GridRenderCellParams) => <>{params && params.value && params.value.toString().replace('GMT+0000 (Greenwich Mean Time)', '')}</>
                    },
                    {
                        field: 'value', disableColumnMenu: false, headerName: "Value", flex: 0.7, minWidth: 100,
                    },
                    // {
                    //     field: 'species', disableColumnMenu: false, headerName: "Species", minWidth: 120, flex: 1, valueGetter: ({ value }) => value && value.name
                    // },
                    // {
                    //     field: 'area', disableColumnMenu: false, headerName: "Area", minWidth: 105, flex: 0.7, valueGetter: ({ value }) => value && value.name,
                    // },
                  
                  
                    {
                        field: '_id',
                        sortable: false,
                        disableColumnMenu: false,
                        headerName: 'Actoins',
                        align: 'right',
                        headerAlign: 'right',
                        flex: 1,
                        minWidth: 150,
                        renderCell: (params: GridRenderCellParams) => {

                            return <>

                                <DebugButton data={params.row} />
                                
                            </>

                        },
                    }

                ]}
                autoPageSize
            /></div>
        </Grid>

        <Grid item md={12}>
            {JSON.stringify(thing)}
        </Grid>
        {/* <SensorForm title="Edit Sensor" data={thing} buttonText="Save" /> */}

    </Grid>
}


export default Sensor