import { Button } from "@mui/material"
import { FormProvider, SubmitHandler } from "react-hook-form"
import { useForm } from "react-hook-form";
import { iSystemSchema } from "../interfaces/iSystemSchema";
import doRequest from "./axios";
import { iPlant } from "../interfaces/iPlant";
import { iObservation } from "../interfaces/iObservation";
import { iToDo } from "../interfaces/iToDo";

import ObservationQuestionsSubForm from "./hookformfields/observation-subform";
import { iArea } from "../interfaces/iArea";
import {  useState } from "react";




interface iObservationFast{
    plant?:iPlant;
    area?: iArea;
    schema:iSystemSchema;
    todo:iToDo;
    done:(a:any)=>void;
    additionalEndButton?:JSX.Element
}
export const ObservationFastForm = ({plant, area, schema, todo, done, additionalEndButton}:iObservationFast) => {
    console.log(plant,schema, todo )
    const methods = useForm<iObservation>({defaultValues:{data:{}, schemaa:schema._id, schemaData:schema, plant:plant, area:area, todo:todo._id }})
    const [loading, setLoading] = useState(false);
    const onSubmit: SubmitHandler<iObservation> =async (data) => {
        setLoading(true)
        console.log(data)
        const n = await doRequest<any>('observation', "POST", data)
                console.log(n)
                
               setTimeout(()=>{
                setLoading(false)
                done(n)},1000);
       

    }
    const {handleSubmit} = methods;
    if(loading){
        return <>loading</>
    }
    return  <FormProvider {...methods}><form onSubmit={handleSubmit(onSubmit)}>
        {additionalEndButton && additionalEndButton}
       <ObservationQuestionsSubForm />

            <Button sx={{mt:2, mb:2}} fullWidth variant="outlined" color="primary" type="submit">Go</Button>
            {additionalEndButton && additionalEndButton}
        </form>
        </FormProvider>
}


const ObservationFast = ({plant, schema, todo}:iObservationFast) => {


    return <ObservationFastForm plant={plant} schema={schema} todo={todo} done={()=>{

    }}/>
}

export default ObservationFast