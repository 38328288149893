import AirIcon from '@mui/icons-material/Air';
// import Brightness7Icon from '@mui/icons-material/Brightness7';
import MemoryIcon from '@mui/icons-material/Memory';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import WaterIcon from '@mui/icons-material/Water';
import WifiIcon from '@mui/icons-material/Wifi';
import { Avatar, Chip, Tooltip } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LightModeIcon from '@mui/icons-material/LightMode';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import LanguageIcon from '@mui/icons-material/Language';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import { iSensor } from '../interfaces/iSensor';
interface iSensorChipProp{
    d:iSensor;
    key?:any;
}

export const SensorIconOnly  = ({ d}: iSensorChipProp) => {
    const type = d.type;
    
    let thing = <></>
    if (type === 'temp') {
        thing = <ThermostatIcon />
    } 
    if (type === 'ip') {
        thing = <LanguageIcon />
    }
    if (type === 'wifi_db') {
        thing = <WifiIcon />
    }
    if (type === 'lux') {
        thing = <LightModeIcon />
    }  
    if (type === 'salt') {
        thing = <FastfoodIcon />
    }     
    if (type === 'restart_counter') {
        thing = <RestartAltIcon />
    }  
    if (type === 'external_power') {
        thing = <ElectricBoltIcon />
    }   
    if (type === 'volts') {
        thing = <Battery0BarIcon />
    }  
    if (type === 'free') {
        thing = <MemoryIcon />
    }
    if (type === 'hum') {
        thing = <AirIcon />
    }
    if (type === 'soil') {
        thing = <WaterIcon />
    }

    return thing;
}

const SensorChip = ({ d, key}: iSensorChipProp) => {
    const navigate = useNavigate();

    //  const aa = (d.type ? d.type : d.code)
    return <Tooltip key={key} title={d.name}><Chip onClick={()=>{
        navigate(`/sensors/${d._id}`)
    }} avatar={<Avatar><SensorIconOnly d={d}/></Avatar>} label={`${d.value}`} /></Tooltip>
       
}


export default SensorChip