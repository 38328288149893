import {  Box, Button, Grid, IconButton, Modal } from "@mui/material";
import { useRef, useState } from "react";
import { Field, FormSpy } from "react-final-form";
import doRequest from "../axios";
import { useFormContext } from "react-hook-form";
import Camera, { IMAGE_TYPES } from 'react-html5-camera-photo';
import { popupStyleFullscreeen } from "../popup";
import CloseIcon from '@mui/icons-material/Close';
interface iTextInput {
    name: string;
    label: string;
    source: string;
    overrideOnChange?: (data: any) => void
}






export const CameraInputFieldHook = ({ name, label, overrideOnChange, source }: iTextInput) => {

    console.log("name", name)
    //  const ml = multiline;
    const { } = useFormContext() // retrieve all hook methods


    const [active, setActive] = useState(false);
    const myCRef = useRef<HTMLDivElement>(null)
    const myBRef = useRef<HTMLDivElement>(null)


    const handleTakePhoto = async (dataUri: any) => {
        // Do stuff with the photo...
        console.log('takePhoto');
        await Go(dataUri)
    }
    const Go = async (image: string) => {
        try {
            setActive(false)
            const file = await doRequest<any>(`image?source=${source}`, 'POST', { d: image , });
            console.log(file)



            setTimeout(() => {

                if (overrideOnChange !== undefined) {
                    overrideOnChange(file)
                } else {
           
                }


            }, 2000);

        } catch (error) {
            console.log(error)
        }

    }
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return <>
       <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"

        >
            
            <Box sx={popupStyleFullscreeen}>
                <IconButton onClick={()=>{
                    handleClose();

                }} sx={{color:'white', position: "relative", right:5, top:5}}>
<CloseIcon />
                </IconButton>
                <div ref={myCRef} />
           
                <Camera
                    imageType={IMAGE_TYPES.JPG}
                    isMaxResolution={true}
                    idealFacingMode={"environment"}
                    onCameraStart={() => myCRef.current && myCRef.current !== null && myCRef.current.scrollIntoView({ behavior: "smooth", block: "start" })}
                    onTakePhoto={(dataUri: any) => {
                        myCRef.current && myCRef.current !== null && myCRef.current.scrollIntoView({ behavior: "smooth", block: "end" })
                        handleTakePhoto(dataUri);
                        handleClose()
                    }}
                />

            </Box>
        </Modal>
        {active ? <div>
            
         

        </div> : <div ref={myBRef}><Button onClick={() => {
            //setActive(true)
handleOpen();
        }}>Camera {label}</Button></div>


        }</>
}



const CameraInputField = ({ name, label, overrideOnChange, source }: iTextInput) => {

    const [active, setActive] = useState(false);
    const myCRef = useRef<HTMLDivElement>(null)
    const myBRef = useRef<HTMLDivElement>(null)
    return <Grid item xs={12}>
        <FormSpy
            subscription={{

            }}
        >
            {({ }) => {


                return <Field name={name}>
                    {({ input: { onChange, value }, meta }) => {
                        value
                        meta;
                        const handleTakePhoto = async (dataUri: any) => {
                            // Do stuff with the photo...
                            console.log('takePhoto');
                            await Go(dataUri)
                        }
                        const Go = async (image: string) => {
                            try {
                                setActive(false)
                                const file = await doRequest<any>(`image?source=${source}`, 'POST', { d: image });
                                console.log(file)



                                setTimeout(() => {

                                    if (overrideOnChange !== undefined) {
                                        overrideOnChange(file)
                                    } else {
                                        onChange(file)
                                    }


                                }, 1000);

                            } catch (error) {
                                console.log(error)
                            }

                        }

                        return <>

                            {active ? <div>
                                <div ref={myCRef} />
                                <Camera
                imageType={IMAGE_TYPES.JPG}
                                    idealFacingMode={"environment"}
                                    onCameraStart={() => myCRef.current && myCRef.current !== null && myCRef.current.scrollIntoView({ behavior: "smooth", block: "start" })}
                                    onTakePhoto={(dataUri: any) => {
                                        myCRef.current && myCRef.current !== null && myCRef.current.scrollIntoView({ behavior: "smooth", block: "end" })
                                        handleTakePhoto(dataUri);
                                    }}
                                />

                            </div> : <div ref={myBRef}><Button onClick={() => {
                                setActive(true)

                            }}>Camera {label}</Button></div>


                            }

                        </>

                    }}
                </Field>

            }}
        </FormSpy>
    </Grid>
}


export default CameraInputField;