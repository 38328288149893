import axios, { AxiosRequestConfig } from "axios";

export const axiosConfig = (): AxiosRequestConfig => {
    let tokenS = window.localStorage.getItem('token');
    if (tokenS !== null) {
        return {
            headers: {
                Authorization: `Bearer ${tokenS}`
            }
        }
    } else {
        return {}

        //throw "No Token"
    }
}
const doRequest = async <T,>(target: string, method: 'GET' | 'POST' | 'FILE' | 'PATCH' | 'DELETE', payload?: any) => {
    // try {
   
    const conf =  axiosConfig();
    console.log("doRequest", conf, target)
    switch (method) {
        case 'POST':
            const r = await axios.post(`/api/${target}`, payload, conf);
            //          
            const response: T = r.data;


            console.log(response)
            return response;


        case "GET":

            const rg = await axios.get(`/api/${target}`,conf);
            console.log(rg)
            const responseG: T = rg.data;
            console.log(responseG)
            return responseG;

        case "PATCH":

            const rp = await axios.patch(`/api/${target}`, payload, conf);
            //          console.log(r.data)
            const responseP: T = rp.data;
            console.log(responseP)
            return responseP;
        case "DELETE":

            const rD = await axios.delete(`/api/${target}/${payload}`, conf);
            //          console.log(r.data)
            const responseD: T = rD.data;
            console.log(responseD)
            return responseD;

        case "FILE":
            var formData = new FormData();

            formData.append("file", payload);
            const rf = await axios.post(`/api/${target}`, formData, conf);
            const responsef: T = rf.data;
            return responsef;


        default:
            return undefined;
    }

}


export default doRequest;