import { Route, Routes } from "react-router-dom";
import SecureRouteAdmin from "../../../util/secureRouteAdmin";
import Debug from "./Debug";


const DebugRoutes = () => {

    return <Routes>
        <Route path="/" element={<SecureRouteAdmin title="Users"><Debug /></SecureRouteAdmin>} />
 
    </Routes>
}


export default DebugRoutes;