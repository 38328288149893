import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import doRequest from "./axios";

interface iStaticContent {
    name: string;
}

const StaticContent = ({
    name
}: iStaticContent) => {
    const [s, setS] = useState<any>();

    useEffect(() => {
        const Go = async () => {
            const response = await doRequest<any>('static', 'POST', { name })
            console.log("static", response)
            if (response && response.X === 'OK') {
                setS(response)
            }
        }
        Go();
    }, [])




    console.log("data", s)
    if (s == undefined) {
        return <CircularProgress />
    }

    return <>{s.Content && <div
        dangerouslySetInnerHTML={{ __html: s.Content }}
    />}</>
}


export default StaticContent