import { CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import doRequest from "../../util/axios";
import AreaForm from "./area-form";
import { iArea } from "../../interfaces/iArea";
import SensorChip from "../../util/sensor-chip";
import LineChart from "../../util/lineChart";
import Notes from "../../util/notes";
const Area = () => {
    const params = useParams();
    const [area, setThing] = useState<iArea>();
    // const [tabsInfo, setTabsInfo] = useState<any[]>();

    useEffect(() => {
        const Go = async () => {
            const response = await doRequest<any>(`area/${params.id}`, 'GET', {})
            console.log("static", response)
            if (response) {
                setThing(response)

            }
        }
        Go();
    }, [params.id])


    if (area === undefined) {
        return <CircularProgress />
    }

    return <Grid container spacing={2}>
         <Grid item sm={12}>
            <Typography variant="h4">{area && area.name} {area && area.weatherLocation}</Typography>

        </Grid>

        <Grid item sm={12}>
      
        {area && area.sensors.map((d: any, di: number) => {
                return <SensorChip key={di} d={d}  />
            })}

            {area && <Notes name="Area" query={{area:area._id, field:'note'}} />}

        </Grid>


        <Grid item sm={12}>
           <Typography variant="h4">Area Averages</Typography>
           <LineChart name="Light" query={{ area: area._id, field: 'lux' }} measurement="lx"  />

            <LineChart name="Air Temp" query={{ area: area._id, field: 'air-temp' }} measurement="C"  />
            <LineChart name="Air Humid" query={{ area: area._id, field: 'air-humidity' }} measurement="%" />

            <LineChart name="Soil Water" query={{ area: area._id, field: 'soil-humidity' }} measurement="%" />

            <LineChart name="Soil Salt" query={{ area: area._id, field: 'salt' }} measurement="ec" />

           <LineChart name="Signal" query={{ area: area._id, field: 'wifi_db' }} measurement="db" />

           {/* {device && <LineChart name="Memory" query={{ device: device._id, field: 'freememory' }} measurement="bits" />}
           
           {device && <LineChart name="Battery" query={{ device: device._id, field: 'volts' }} measurement="v" />} */}

       </Grid>
        <Grid item md={12}>
            {JSON.stringify(area)}
        </Grid>

        <AreaForm title="Edit Area" data={area} buttonText="Save" />

    </Grid>
}


export default Area