import { Button, CardContent, CardMedia, Grid, Typography } from "@mui/material"
import { useState } from "react"
import { FileInputFieldHook } from "../fields/file-field"
import { CameraInputFieldHook } from "../fields/camera-field"

interface iImagesFieldProps {
    value: any
    source: string;

    update: (value: any) => void
}
const ImagesField = ({ value, update, source }: iImagesFieldProps) => {
    const [thisValue, setThisValue] = useState<any[]>(() => {
        if (value) {
            return value
        } else {
            return []
        }
    })

    const addNew = (thing: any) => {
        const clone = [...thisValue];
        clone.push(thing);
        setThisValue(clone);
        update(clone)
    }
    return <>

        <Grid item container xs={12} spacing={2} sx={{ paddingTop: 2, }}>
            {thisValue.map((image: any, index: number) => {
                let src = '';
                if (image.displayUrl) {
                    src = `/api/uploads/${image.displayUrl}`;
                }
                return <Grid item xs={6} sm={4} key={index}>
                    <CardMedia
                        component="img"
                        height="240"
                        image={src}
                        alt="email image"

                    /> <CardContent sx={{ padding: 0, margin: 0 }}>
                        <Typography gutterBottom variant="h5" component="div">

                            <Button color="secondary" type="button" onClick={ev => {
                                ev.preventDefault();
                                // fields.remove(index)
                            }}>
                                del
                            </Button>
                        </Typography>

                    </CardContent>

                </Grid>



            })}

            <Grid item xs={12}>
                <FileInputFieldHook name="na" label="Add Image" source={source} overrideOnChange={(d: any) => {
                    addNew({ ...d })
                }} />
                <CameraInputFieldHook name="na" label="Add Image" source={source} overrideOnChange={(d: any) => {
                    addNew({ ...d })
                }} />
            </Grid>
        </Grid>



    </>
}

export default ImagesField;