import EditIcon from '@mui/icons-material/Edit';
import { Button, CircularProgress, Grid, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import doRequest from "../../util/axios";
import DebugButton from "../../util/debugButton";
import { tableBoxHolderProps } from '../../util/pageWrapper';
import DeleteIcon from '@mui/icons-material/Delete';
import SensorBurger from '../../util/sensor-burger';




const Areas = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [things, setThings] = useState<any[]>()
    const navigate = useNavigate();
    useEffect(() => {
        const Go = async () => {
            if (loading === false) {
                setLoading(true)

                const response = await doRequest<any>("area", "GET",)
                setThings(response)

            }
        }
        Go();
    }, []);
    if (things === undefined) {
        return <CircularProgress />
    }

    return <Grid container spacing={2}>
        <Grid item xs={12}>

            <Box>

                <Box sx={tableBoxHolderProps}>
                    <Button variant="outlined" onClick={ev => {
                        ev.preventDefault();
                        navigate && navigate('./new')
                    }}>New</Button>
                    <DataGrid
                        getRowId={(r: any) => r._id}
                        disableColumnSelector
                        rows={things}
                        // rowHeight={172}
                        columns={[
                            {
                                field: 'name', disableColumnMenu: true, headerName: "Name", flex: 2, minWidth: 150,
                            },

                            {
                                field: 'context', disableColumnMenu: true, headerName: "Context", flex: 2, minWidth: 150,
                            },
                            {
                                field: 'code', disableColumnMenu: true, headerName: "Code", flex: 0.5, minWidth: 50,
                            },
                            {
                                field: 'weatherLocation', disableColumnMenu: true, headerName: "Weather Location", flex: 2, minWidth: 150,
                            },
               
                            {
                                field: '_id',
                                sortable: false,
                                disableColumnMenu: true,
                                headerName: '',
                                align: 'right',
                                headerAlign: 'right',
                                flex: 1, minWidth: 100,

                                renderCell: (params: GridRenderCellParams) => {

                                    return <>
                                        <DebugButton data={params.row} />
                                        <SensorBurger sensors={params.row.sensors} />
                                        <IconButton color="primary" onClick={() => navigate && navigate(`/areas/${params.row._id}`)}><EditIcon /></IconButton>
                                        <IconButton onClick={()=>console.log(params.row._id)}><DeleteIcon /></IconButton>
                                    </>

                                },
                            }

                        ]}
                        autoPageSize
                    />

                </Box>
                <DebugButton data={things} />

            </Box >
        </Grid>

    </Grid>
}



export default Areas