import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import EngineeringIcon from '@mui/icons-material/Engineering';
import StoreIcon from '@mui/icons-material/Store';
;
import { Link as Link2} from "react-router-dom";
import { Avatar, Box, Button, Grid, SxProps, Typography } from '@mui/material';

const boxSX: SxProps = {
    border: 1, borderRadius: 3, textAlign: 'center', p: 5, height: "450px", borderColor: "purple"
}


interface iAdminBlock {
    title: string;
    text: string;
    link: string;
    linkText: string;
    icon: any;
}

const AdminBlock = ({title, text, link, linkText, icon}: iAdminBlock) => {

    return <Grid item xs={12} md={6} lg={4}>
        <Box sx={boxSX}>
            <Typography variant="h5">{title}</Typography>
            <Avatar sx={{width: 156, height: 156, margin: "auto", mt: 5, backgroundColor: "purple"}}>
                {icon}
            </Avatar>
            <Typography variant="subtitle1" sx={{mt: 5}}>{text}</Typography>
            <Button fullWidth variant="outlined" component={Link2} to={link} sx={{mt: 5}}>{linkText}</Button>
        </Box>
    </Grid>
}


const Admin = () => {


    return <Grid container spacing={2} sx={{pt: 5, pl: 10, pr: 10}}>


        <AdminBlock title="Users" text="See all users, add new users and edit existing users." link="/admin/users"
                    linkText="Edit Users" icon={<SupervisedUserCircleIcon sx={{width: 156, height: 156}}/>}/>
        <AdminBlock title="Settings" text="Update other configuration settings." link="/admin/settings"
                    linkText="Edit Settings" icon={<EngineeringIcon sx={{width: 126, height: 126}}/>}/> 
        <AdminBlock title="Debug" text="Debug tools." link="/admin/debug"
                    linkText="Debug" icon={<EngineeringIcon sx={{width: 126, height: 126}}/>}/>
        <AdminBlock title="Sites" text="See all sites, add new sites and edit existing sites." link="/admin/sites"
                    linkText="Edit Sites" icon={<StoreIcon sx={{width: 126, height: 126}}/>}/>
     

    </Grid>
}

export default Admin;