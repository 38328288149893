import { CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import doRequest from "../../util/axios";
const Observation = () => {
    const params = useParams();
    const [thing, setThing] = useState<any>();
    // const [tabsInfo, setTabsInfo] = useState<any[]>();

    useEffect(() => {
        const Go = async () => {
            const response = await doRequest<any>(`observation/${params.id}`, 'GET', {})
            console.log("static", response)
            if (response) {
                setThing(response)

            }
        }
        Go();
    }, [params.id])


    if (thing === undefined) {
        return <CircularProgress />
    }

    return <Grid container spacing={2}>
        {JSON.stringify(thing)}
    </Grid>
}


export default Observation