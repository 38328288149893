import { Route, Routes } from "react-router-dom";
import Species from "./species";
import SpeciesNew from "./species-new";
import Speciess from "./speciess";
import SecureRoute from "../../util/secureRoute";



const SpeciesRoutes = () => {

    return <Routes>
        <Route path="/" element={<SecureRoute title="Species"><Speciess /></SecureRoute>} />
        <Route path="/:id" element={<SecureRoute title="Species"><Species /></SecureRoute>} />
        <Route path="/new" element={<SecureRoute title="New Species"><SpeciesNew /></SecureRoute>} />
    </Routes>
}


export default SpeciesRoutes;