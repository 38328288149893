import doRequest from "../../util/axios";
import DeviceForm from "./device-form";
const DeviceNew = () => {

    return <DeviceForm onSubmit={(async (d: any) => {
        const n = await doRequest<any>('device', "POST", d)
        console.log(n)
    })} data={{}} />
}


export default DeviceNew