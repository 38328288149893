import { Box, Button, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { iDeviceSetting } from "../../interfaces/iDevice";
import DebugButton from "../../util/debugButton";
import NumberField from "../../util/hookformfields/number";
import doRequest from "../../util/axios";
import { useState } from "react";
import { popupStyle } from "../../util/popup";

interface iDSChangeProp{
    setting: iDeviceSetting
    deviceId:string;
}







const ChangeDeviceSetting = ({setting, deviceId}:iDSChangeProp) =>{
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    
const [value, setValue] = useState(()=>setting.value);
       
    const doCommand = async () => {
      
      const response = await doRequest<any>(`device/doCmd`, 'POST', { deviceId, action:"SET_SETTING",payload:`${setting.name}=${value}` })
      console.log("static", response)
      handleClose();
    
  }

    return <>
    <Button onClick={handleOpen}>Edit</Button>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={popupStyle}>
                <Typography variant="h6">
                {setting.name} = {setting.value}
                </Typography>

             
    <Typography variant="body1">
    <NumberField value={value} update={setValue}/>
    <Button onClick={()=>{
doCommand();
    }}>Save / Send to Device</Button>
    <Button onClick={handleClose}>Edit</Button>
                </Typography>

            </Box>
        </Modal>

    </>
}
   
interface iDSProp{
    settings: iDeviceSetting[]
    deviceId:string;
}







const DeviceSettings = ({settings, deviceId}:iDSProp) =>{

    return <>
       <Typography variant="h6">Settings</Typography>
    
    <TableContainer component={Paper}>
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
        
          <TableCell>Name</TableCell>
          <TableCell>Value</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {settings.map((item, index) => {

          return <TableRow key={index}>
            <TableCell>
              {item.name}
            </TableCell>
            <TableCell>
              {item.value}
            </TableCell>
           
            
            <TableCell>
                <ChangeDeviceSetting setting={item} deviceId={deviceId} />
              <DebugButton data={item} />
            </TableCell>
          </TableRow>
        })}
      </TableBody>
    </Table>

  </TableContainer>
  </>
}


export default DeviceSettings;