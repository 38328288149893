
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import CloseIcon from '@mui/icons-material/Close';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Line } from 'react-chartjs-2';
import doRequest from './axios';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useRef, useState } from 'react';
import DebugButton from './debugButton';
import {  Box, Button,  CircularProgress,  Dialog,  IconButton,  ListItemText, Menu, MenuItem, Switch, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { iGraphData } from '../interfaces/iData';


ChartJS.register(
  annotationPlugin,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);



interface iLineChartProps {
  query: any;
  name: string;
  measurement: string;
  upperBoundry?: number;
  lowerBoundry?: number;
 // displayOptions: boolean;
}
export const serialize = (obj: any) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

const timeRanages = [
  '6hr', 
  'today', 
  'yesterday', 
  '2-days-ago',
  'last-24hr',
  'all',
];

interface iLineTimeRangeProp{
  timeRange:string;
  Go:any;
}
const LineTimeRanges = ({timeRange, Go}:iLineTimeRangeProp) =>{

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return <>
    <Button variant='outlined' onClick={handleClick}>Range: {timeRange}</Button>
  
  <Menu
    
  anchorEl={anchorEl}
  open={open}
  onClose={handleClose}
  MenuListProps={{
    'aria-labelledby': 'basic-button',
  }}
>
  {timeRanages.map((range, index)=>{
  
  
    return <MenuItem key={index} onClick={()=>{
  
      Go(range);
      handleClose();
    }}>

    <ListItemText>{range}</ListItemText>
  
  </MenuItem>})}
</Menu>

</>
}

const LineChart = ({ query, name, measurement, upperBoundry, lowerBoundry }: iLineChartProps) => {
  const [sourceData, setSourceDatas] = useState<iGraphData[]>([]);
  const [resultMeta, setResultMeta] = useState<any>({});
  const [useRaw, setUseRaw] = useState<boolean>(false);

  const mounted = useRef(false)
  const [loading, setLoading] = useState(true);

  const [timeRange, setTimeRage] = useState('6hr');
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Go = async (timeRangeD?:string) => {
    if(timeRangeD){
      setTimeRage(timeRangeD);
    } else {
      timeRangeD = timeRange;
    }
    setLoading(true)
    const response = await doRequest<any>(`data/__Graph/?${serialize({ ...query,timeRange:timeRangeD })}`, 'GET', {})
    console.log("static", response)
    if (response.result) {
      const {result, ...alt} = response;
      console.log(result, alt)
      setResultMeta(alt);
      setSourceDatas(result.sort((a: any, b: any) => {
        return (new Date(a._id) >= new Date(b._id))
      }))
    }
    setLoading(false)

  }

  useEffect(() => {
    if (mounted.current === false) {
      mounted.current = true;
      Go();
    }
  }, [query])
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseRaw(event.target.checked);
  };



  const options: any = {
    responsive: true,
    plugins: {
      annotation: {
        annotations: {
        }
      },
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
        text: name,
      },
    },
  };

  if (upperBoundry) {
    options.plugins.annotation.annotations.lineUpper = {
      type: 'line',
      yMin: upperBoundry,
      yMax: upperBoundry,
      borderColor: 'rgb(255, 99, 132)',
      borderWidth: 2,
    }
  }


  if (lowerBoundry) {
    options.plugins.annotation.annotations.lineLower = {
      type: 'line',
      yMin: lowerBoundry,
      yMax: lowerBoundry,
      borderColor: 'rgb(255, 99, 132)',
      borderWidth: 2,
    }
  }

  try {

    const data = {
      labels: sourceData.map((log) => {
        if (log._id !== null) {

          const day = dayjs(log._id);
          return day.format('DD/MM H:mm')

        } else {
          return '';
        }

      }),
      datasets: [
        {
          label: "average",
          data: sourceData.map((log) => {
            if(useRaw===false){
              return log.avg
            } else {
              return log.avgRaw
            }
          }),
          borderColor: 'rgb(0, 0, 255)',

        },
        {
          label: "min",
          data: sourceData.map((log) => {
            if(useRaw===false){
              return log.min
            } else {
              return log.minRaw
            }
          }),
          borderColor: 'rgba(13, 252, 77)',
        
        },
        {
          label: "max",
          data: sourceData.map((log) => {
            if(useRaw===false){
              return log.max
            } else {
              return log.maxRaw
            }
          }),
          borderColor: 'rgba(255, 169, 97, 0.5)',

        },
     
      ],
    };return <>


<Dialog
        fullScreen
         open={open}
        onClose={handleClose}
      ><Box sx={{position:'absolute', left:10}}>
        <Typography variant='h6'>{name}</Typography>


        </Box>
        <Box sx={{position:'absolute', right:10}}>

  <IconButton
            
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

        </Box>
   
        {sourceData && sourceData.length > 0 && <Line
        options={options}
        data={data} />}

 
      </Dialog>

    <Box sx={{border:1, mb:5, p:2, borderRadius:5, borderColor:'light-grey'}}>
     <Typography variant='h6'>{name} ({measurement})
     <Box>
      <IconButton onClick={handleClickOpen}>
        <SearchIcon />
        </IconButton>
           <LineTimeRanges timeRange={timeRange} Go={Go} /> 
           Raw Values<Switch
          checked={useRaw}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />    
      </Box>
      
     
     </Typography>


     {loading ? <CircularProgress /> : <>
     
     
        {sourceData && sourceData.length > 0 && <Line     
          options={options}
          data={data} />}

 
        <DebugButton data={data} buttonText='Data' />

        <DebugButton data={query} buttonText='Query' />
      <DebugButton data={resultMeta} buttonText='Meta' />
     </>}
  </Box>
  </>;
  } catch (err:any) {
    return <>Error <DebugButton data={err} /></>;
  }

}

export default LineChart;