import { Route, Routes } from "react-router-dom";
import Users from "./users";
import User from "./user";
import UserNew from "./user-new";
import SecureRouteAdmin from "../../../util/secureRouteAdmin";


const UserRoutes = () => {

    return <Routes>
        <Route path="/" element={<SecureRouteAdmin title="Users"><Users /></SecureRouteAdmin>} />
        <Route path="/:id" element={<SecureRouteAdmin title="User"><User /></SecureRouteAdmin>} />
        <Route path="/new" element={<SecureRouteAdmin title="New User"><UserNew /></SecureRouteAdmin>} />
    </Routes>
}


export default UserRoutes;