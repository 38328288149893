import { Route, Routes } from "react-router-dom";

import UserRoutes from "./user";
import Admin from "./admin";
import SecureRouteAdmin from "../../util/secureRouteAdmin";
import DebugRoutes from "./debug";


const AdminRoutes = () => {

    return <Routes>
         <Route path="/" element={<SecureRouteAdmin title="Home"><Admin /></SecureRouteAdmin>} />
        <Route path="users/*" element={<UserRoutes />} />
        <Route path="debug/*" element={<DebugRoutes />} />
     
    </Routes>
}

export default AdminRoutes;