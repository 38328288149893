import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Button, CircularProgress, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import doRequest from "../../util/axios";
import DebugButton from "../../util/debugButton";
import { iSpecies } from '../../interfaces/iSpecies';
const Speciess = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [things, setThings] = useState<iSpecies[]>()
    const navigate = useNavigate();
    useEffect(() => {
        const Go = async () => {
            if (loading === false) {
                setLoading(true)

                const response = await doRequest<iSpecies[]>("species", "GET",)
                setThings(response)

            }
        }
        Go();
    }, []);

    const deleteThing = async (id: string) => {
        const b = window.confirm('Delete?')
        if (b) {

            const response = await doRequest<any>("species", "DELETE", id)
            console.log(response)
            if (things) {
                const ind = things.findIndex(d => d._id === id)
                const newThings = [...things];

                newThings.splice(ind, 1);

                setThings(newThings)
            }
        }
    }
    if (things === undefined) {
        return <CircularProgress />
    }

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <Box>
                <div style={{ height: '500px', width: "100%" }}>
                    <Button variant="outlined" onClick={ev => {
                        ev.preventDefault();
                        navigate && navigate('/species/new')
                    }}>New
                    </Button>
                    <DataGrid
                        getRowId={r => r._id}
                        disableColumnSelector
                        rows={things}
                        rowHeight={72}
                        columns={[
                            {
                                field: 'image', disableColumnMenu: false, align: 'center', headerName: "", width: 72, renderCell: (params: GridRenderCellParams<any>) => {
                                    if (params.value && params.value.displayUrl) {
                                        const src = `/api/uploads/${params.value.displayUrl}`;
                                        return <Avatar alt="" src={src} sx={{ width: 66, height: 66 }} />;
                                    } else {
                                        return <></>
                                    }
                                }
                            },
                            {
                                field: 'name', disableColumnMenu: false, headerName: "name", flex: 1, minWidth: 100
                            },
                            {
                                field: 'scienceName', disableColumnMenu: false, headerName: "Science Name", flex: 1, minWidth: 100
                            },
                            {
                                field: 'code', disableColumnMenu: false, headerName: "Prefix Code", flex: 0.5, minWidth: 100,
                            },
                            {
                                field: '_id',
                                sortable: false,
                                disableColumnMenu: false,
                                headerName: '',
                                align: 'right',
                                headerAlign: 'right',
                                flex: 0.5,
                                minWidth: 150,
                                renderCell: (params: GridRenderCellParams) => {

                                    return <>
                                        <Button variant="outlined" onClick={() => navigate && navigate(`/species/${params.row._id}`)}>
                                            <EditIcon />
                                        </Button>
                                        <Button variant="outlined" onClick={async () => {
                                            await deleteThing(params.row._id)


                                        }}><DeleteIcon /></Button>

                                    </>

                                },
                            }
                        ]}
                        autoPageSize
                    />

                </div>
                <DebugButton data={things} />

            </Box >
        </Grid>

    </Grid>
}


export default Speciess