import doRequest from "../../util/axios";
import { useEffect, useRef, useState } from "react";
import { iPlant } from "../../interfaces/iPlant";
import { iArea } from "../../interfaces/iArea";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import DebugButton from "../../util/debugButton";
import PsText from "../../util/none-observation-fields/text";
import DisplayDate from "../../util/display-date";



const DeviceUpdateForm = () => {


  const mounted = useRef(false);
  //   const [schemas, setSchemas] = useState<iSystemSchemaQuestion[]>([]);
  const [plants, setPlants] = useState<iPlant[]>([]);
  const [areas, setAreas] = useState<iArea[]>([]);
  const { control, watch, setValue } = useFormContext();

  const { fields, } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "sensors", // unique name for your Field Array
  });
  useEffect(() => {
    const Go = async () => {
      //get schemas

      const areasA = await doRequest<iArea[]>(`area`, "GET");
      console.log(areasA)
      areasA && setAreas(areasA);
      const plantsA = await doRequest<iPlant[]>(`plant`, "GET");
      console.log(plantsA)
      plantsA && setPlants(plantsA);

    }
    if (mounted.current === false) {
      mounted.current = true;
      Go();
    }
  }, [])
  const area = watch('area');
  const plant = watch('plant');
  const sensors = watch('sensors');
  //  const area = watch('area');
  return <>
    {plant} {area}

    <PsText name="name" label="Name" required />
    <Controller
      name="plant"
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
          <InputLabel>Device Main Plant</InputLabel>
          <Select
            value={value}
            onChange={(event: SelectChangeEvent) => {

              if (event.target.value !== '') {
                // updte sensors
                const plantFound = plants.find(s => s._id === event.target.value);
                const newSensors = [];
                for (const sensor of sensors) {


                  const oldplant = plants.find(s => s._id === plant);
                  if (sensor.plant === plant) {
                    sensor.plant = event.target.value as string;
                  }
                  console.log(sensor, oldplant, plantFound)
                  if (typeof oldplant?.area !== 'string' && sensor.area === oldplant?.area._id) {
                    sensor.area = typeof plantFound?.area !== 'string' && plantFound?.area._id
                  }


                  newSensors.push(sensor)
                }
                setValue('area', typeof plantFound?.area !== 'string' && plantFound?.area._id)
                setValue('sensors', newSensors)


                //  onChange(event.target.value as string)
              }
              onChange(event.target.value as string);

            }
            }
            label="plant"
            fullWidth
          >
            <MenuItem value="">None - Area Only</MenuItem>
            {plants && plants.map((thing, index) => {
              return <MenuItem key={index} value={thing._id}>{thing.name} @ {typeof thing.area !== 'string' && thing.area.name}</MenuItem>
            })}
          </Select>
        </FormControl>
      )}
    />

    {plant === '' && <Controller
      name="area"
      control={control}
      render={({ field }) => (
        <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
          <InputLabel>Plant</InputLabel>
          <Select
            {...field}
            fullWidth
          >
            <MenuItem></MenuItem>
            {areas && areas.map((thing, index) => {
              return <MenuItem key={index} value={thing._id}>{thing.name}</MenuItem>
            })}
          </Select>
        </FormControl>
      )}
    />}

    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Plant</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Raw Value</TableCell>
            <TableCell>Last</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {fields.map((item, index) => {

            return <TableRow key={item.id}>
              <TableCell>
                {sensors[index].type}
              </TableCell>
              <TableCell>
                {sensors[index].name}
              </TableCell>
              <TableCell>

                {sensors[index].isDeviceOnly !== true && <Controller
                  name={`sensors.${index}.plant`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth>
                      <InputLabel>Plant</InputLabel>
                      <Select
                        value={value}
                        onChange={(event: SelectChangeEvent) => {



                          onChange(event.target.value as string);
                        }
                        }
                        label="plant"
                        fullWidth
                      >
                        <MenuItem value="">None - Area Only</MenuItem>
                        {plants && plants.map((thing, index) => {
                          return <MenuItem key={index} value={thing._id}>{thing.name} {typeof thing.area === "string" ? thing.area : thing.area.id}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                  )}
                />}
              </TableCell>
             
              <TableCell>
                {sensors[index].value}
              </TableCell>
              <TableCell>
                {sensors[index].rawValue}
              </TableCell>
              <TableCell>
              <DisplayDate date={sensors[index].lastConnected} />
              </TableCell>
              <TableCell>
                <DebugButton data={item} />
              </TableCell>
            </TableRow>
          })}
        </TableBody>
      </Table>

    </TableContainer>

  </>
}



export default DeviceUpdateForm