import { FormSpy } from "react-final-form";
import { useNavigate } from "react-router-dom";
import doRequest from "../../util/axios";
import CheckboxInputField from "../../util/fields/checkboxField";
import NumberInputField from "../../util/fields/number-field";
import TextInputField from "../../util/fields/text-field";
import FormContainer from "../../util/form-container";

const SensorForm = ({ title, buttonText, data }: any) => {
    const navigate = useNavigate();
    return <FormContainer
        data={data}
        title={title}
        requiredFields={[]}
        buttonText={buttonText}
        onSubmit={(async (d: any) => {
            console.log(d)

            if (d._id) {
                const n = await doRequest<any>(`sensor/${d._id}`, "PATCH", d)
                console.log(n)
                navigate && navigate('../', { replace: false });
            } else {
                const n = await doRequest<any>('sensor', "POST", d)
                console.log(n)
                navigate && navigate('../', { replace: false });
            }

        })}>

        <TextInputField name="code" label="Code" required={true} />
        <TextInputField name="type" label="Type" />
        <CheckboxInputField name="isDigital" label="is this a digital sensor" />
        <FormSpy
            subscription={{
                values: true
            }}
        >
            {({ values }) => {
                return <>{values.isDigital === false && <>
                    <NumberInputField name="calibrateLowerInput" label="Calibrate Lower Input" type="float" />
                    <NumberInputField name="calibrateUpperInput" label="Calibrate Upper Input" type="float" />
                    <NumberInputField name="calibrateLowerOutput" label="Calibrate Lower Output" />
                    <NumberInputField name="calibrateUpperOutput" label="Calibrate Upper Output" />
                </>}</>
            }}
        </FormSpy>

        <TextInputField name="attachType" label="attachType" />
        <TextInputField name="attachRef" label="attachRef" />
    </FormContainer>
}


export default SensorForm;