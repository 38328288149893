import { Box, SxProps } from "@mui/material";

const ImageDisplay = ({ image }: any) => {


    if (image && image.displayUrl) {
        const src = `/api/uploads/${image.displayUrl}`;

        const ss:SxProps = { height: "90%", width:"90%", m:2, textAlign: "center",p:2 }
        if(image.status === 'danger'){
            
        }
        return <><Box sx={ss} component="img" src={src}></Box>
        {image.status === 'danger' && 'Person Detected'}

        
        </>;
    } else {
        return <></>
    }


}


export default ImageDisplay;