import { TextField } from "@mui/material"
import { useState } from "react"

interface iNumberFieldProps{
    value: number
    update:(value:number)=>void
}
const NumberField = ({value, update}:iNumberFieldProps)=>{
    const [thisValue, setThisValue] = useState(()=>{
        if(value){
        return value
        } else {
            return 0
        }
    })
    return     <TextField
    fullWidth
    type="number"
    value={thisValue}
    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setThisValue(parseFloat(event.target.value));
        update(parseFloat(event.target.value));
    }}
/>
}

export default NumberField;