import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Dialog, IconButton, Slide, Toolbar,  Typography, Chip } from "@mui/material";
import BugReportIcon from '@mui/icons-material/BugReport';
import { TransitionProps } from "@mui/material/transitions";
import React, { useState } from "react";
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IDebugButon {
    data: any;
    alwaysDisplay?: boolean
    buttonText?: string
}
const DebugButton = ({ data, buttonText  }: IDebugButon) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    return (<><IconButton color="secondary" type="button" onClick={(ev: React.MouseEvent) => {
            ev.preventDefault();
            setShow(!show);
        }}> 
        
        {buttonText ? <Chip color="secondary"  label={buttonText} avatar={<BugReportIcon />}></Chip>: <BugReportIcon />}
        </IconButton>
        <Dialog
            fullScreen
            open={show}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {buttonText !== undefined ? buttonText : 'Data Debug'}
                    </Typography>

                </Toolbar>
            </AppBar>
            {JSON.stringify(data)}
        </Dialog>

    </>);
}

export default DebugButton;