import EditIcon from '@mui/icons-material/Edit';
import { Button, ButtonGroup, CircularProgress, Grid, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import doRequest from "../../util/axios";
import DebugButton from "../../util/debugButton";
import DeleteIcon from '@mui/icons-material/Delete';
import { tableBoxHolderProps } from '../../util/pageWrapper';
import { useNotify } from '../../util/notify';
import { iDevice } from '../../interfaces/iDevice';
import GotoButton from '../../util/goto-button';
import DisplayDate from '../../util/display-date';
import DisplayDuration from '../../util/display-duration';
import { socket } from '../../util/context';
import SensorBurger from '../../util/sensor-burger';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { deleteDevice, setDevices, updateDevice } from '../../store/devices-slice';


interface iDisplayDevices {
    //devices: iDevice[];

    deleteDevice: (d: string) => void;
}
const DisplayDevices = ({ deleteDevice }: iDisplayDevices) => {
    const navigate = useNavigate();
   
    const devices = useAppSelector((state) => state.devices.devices)
    console.log(devices)




    return <Box>

        {devices && <Box sx={tableBoxHolderProps}>
            <Button variant="outlined" onClick={ev => {
                ev.preventDefault();
                navigate && navigate('./new')
            }}>New</Button>
            <DataGrid <iDevice>
                getRowId={(r: any) => r._id}
                disableColumnSelector

                rows={devices}
                rowHeight={72}
                columns={[

                    {
                        field: 'name', disableColumnMenu: false, headerName: "Name", flex: 0.7, minWidth: 100
                    },
                    {
                        field: 'code', disableColumnMenu: false, headerName: "Code", flex: 0.7, minWidth: 150
                    },
                    {
                        field: 'plant', disableColumnMenu: false, headerName: "Plant", flex: 2, valueGetter: ({ value }) => value && value.name, minWidth: 200,
                        renderCell: (params: GridRenderCellParams) => <>{params.value} {params.row.plant && <GotoButton to={`/plants/${params.row.plant._id}`} />}</>,
                    },
                    {
                        field: 'area', disableColumnMenu: false, headerName: "Area", flex: 2, valueGetter: ({ row }) => row && row.area && typeof row.area !== 'string' && row.area.name, minWidth: 200,
                        renderCell: (params: GridRenderCellParams) => <>{params.value} {params.row.area && <GotoButton to={`/areas/${params.row.area._id}`} />}</>,
                    },
                    {
                        field: 'lastConnected', disableColumnMenu: false, headerName: "Last Connected", flex: 1, minWidth: 190, type: 'datetime',
                        renderCell: (params: GridRenderCellParams) => <>
                            {params && params.value && <>
                                <DisplayDate date={params && params.value} />
                                <br />
                                <DisplayDuration from={params && params.value} />

                            </>}

                        </>,
                        valueGetter: ({ value }) => value && new Date(value),
                    },
                    {
                        field: 'lastStartedUp', disableColumnMenu: false, headerName: "Last Started Up", flex: 1, minWidth: 190, type: 'datetime',
                        renderCell: (params: GridRenderCellParams) => <>
                            {params && params.value && <>
                                <DisplayDate date={params && params.value} />
                                <br />
                                <DisplayDuration from={params && params.value} />

                            </>}

                        </>,
                        valueGetter: ({ value }) => value && new Date(value),
                    },
                    {
                        field: 'lastBootUp', disableColumnMenu: false, headerName: "Last Boot Up", flex: 1, minWidth: 190, type: 'datetime',
                        renderCell: (params: GridRenderCellParams) => <>
                            {params && params.value && <>
                                <DisplayDate date={params && params.value} />
                                <br />
                                <DisplayDuration from={params && params.value} />

                            </>}

                        </>,
                        valueGetter: ({ value }) => value && new Date(value),
                    },
                    {
                        field: 'firmwareVersion', disableColumnMenu: false, headerName: "Firmware", flex: 1, minWidth: 175,
                    },
                    {
                        field: '_id',
                        sortable: false,
                        disableColumnMenu: false,
                        headerName: '',
                        flex: 0.5
                        , minWidth: 175,
                        renderCell: (params: GridRenderCellParams) => {

                            return <ButtonGroup>
                                <DebugButton data={params.row} />
                                <SensorBurger sensors={params.row.sensors} />
                                <IconButton onClick={() => navigate && navigate(`/devices/${params.row._id}`)}><EditIcon /></IconButton>
                                <IconButton onClick={() => deleteDevice(params.row._id)}><DeleteIcon /></IconButton>
                            </ButtonGroup>

                        },
                    },

                ]}
                autoPageSize
            />

        </Box>}
        <DebugButton data={devices} />

    </Box >
}



const Devices = () => {
    const [loading, setLoading] = useState<boolean>(false);
  //  const [devices, setDevices] = useState<iDevice[]>()
    const { displayNote } = useNotify();
    const dispatch = useAppDispatch();
    //const mounted = useRef(false)
    const Go = async () => {
        if (loading === false) {
            setLoading(true)

            const response = await doRequest<iDevice[]>("device", "GET",)
            if(response){
                dispatch(setDevices(response))
            }
            setLoading(false)
            // socket.on('device', onDevice);
        }
    }

    useEffect(() => {

        Go();

    }, []);


    useEffect(() => {

        const onDevice = async (payload: iDevice) => {
            console.log("onDevice", payload)
            dispatch(updateDevice(payload))

        }
      
        socket.on('device', (device) => onDevice(device));
        socket.emit('subscribeDevices')
      //  }
        return () => {
            socket.emit('unSubscribeDevices')
            socket.off('device', (device) => onDevice(device));
        };
    }, []);
    const Delete = async (id: string) => {
        //   if (loading === false) {

        if (window.confirm("Are you sure")) {
            setLoading(true)

            const response = await doRequest<any>("device", "DELETE", id)
            if (response) {

                displayNote && displayNote("Deleted Device", 'success')
                dispatch(deleteDevice(id))

            }
            setLoading(false);
        }
    }
    if (loading === true) {
        return <CircularProgress />
    } 


    return <Grid container spacing={2}>

        <Grid item xs={12}>
           <DisplayDevices  deleteDevice={Delete} /> 
          
        </Grid>

    </Grid>
}



export default Devices