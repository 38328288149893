import { Grid, InputAdornment, TextField } from "@mui/material";
import { Field, FormSpy } from "react-final-form";
import { composeValidators, requiredValidate } from "./validators";

interface iTextInput {
    name: string;
    label: string;
    type?: 'string' | 'email' | 'password';
    ml?: boolean;
    required?: boolean;
    unitsText?: string;
}

const TextInputField = ({ name, label, type = 'string', required = false, ml = false, unitsText }: iTextInput) => {

    //  const ml = multiline;


    let fieldProps: any = {
        name
    }
    if (required === true) {
        fieldProps.validate = composeValidators(requiredValidate)
    }

    let textFiledProps: any = {
        autoComplete: (type === 'password' ? 'off' : 'new-password'),
        name,
        label,
        fullWidth: true,
        type,
        multiline: ml,
        required,
        variant: "outlined",
        color: "secondary",
    }


    if (unitsText) {
        textFiledProps.InputProps = { endAdornment: <InputAdornment sx={{ textColor: '500:grey', fontSize: '0.4em' }} position="end" >{unitsText}</InputAdornment> };
    }

    return <Grid item xs={12}>
        <FormSpy
            subscription={{
                dirtySinceLastSubmit: true,
                submitting: true,
            }}
        >
            {({ dirtySinceLastSubmit, submitting }) => (



                <Field {...fieldProps}>
                    {({ input, meta }) => {
                        return <>
                            <TextField
                                {...input}
                                error={
                                    (meta.error && meta.touched) ||
                                    (!!meta.submitError &&
                                        !dirtySinceLastSubmit &&
                                        !submitting)
                                }
                                helperText={
                                    meta.error && meta.touched
                                        ? meta.error
                                        : !!meta.submitError &&
                                            !dirtySinceLastSubmit &&
                                            !submitting
                                            ? meta.submitError
                                            : ""
                                }
                                {...textFiledProps}
                            />

                        </>
                    }}
                </Field>
            )}
        </FormSpy>
    </Grid>
}


export default TextInputField;