import { ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { iUser } from "../interfaces/iUser";
import axios, { AxiosRequestConfig } from "axios";
import { Socket, io } from 'socket.io-client';
import DebugButton from "./debugButton";

// "undefined" means the URL will be computed from the `window.location` object



const URL = (import.meta.env.PROD === true ? "wss://enceladus.photonsurge.uk": "ws://localhost:8888/")
console.log("WSS", URL, import.meta.env.PROD)
export const socket:Socket = io(URL, {
    autoConnect: false,
    extraHeaders: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`
    }
});
export const axiosConfig = (): AxiosRequestConfig => {
    let tokenS = window.localStorage.getItem('token');
    if (tokenS !== null) {
        return {
            headers: {
                Authorization: `Bearer ${tokenS}`
            }
        }
    } else {
        console.log("No Token")
        throw "No Token"
    }
}

type Props = {
    children?: ReactNode
}

type AuthContext = {
    token: string | undefined
    auth: iUser | null
    authLoaded: boolean;
    logIn?: (values: any) => iUser | null,
    setAuth?: (newState: iUser | null) => void
    signIn?: (values: any) => boolean
    signOut?: () => boolean

    loadUser?: () => void;
}

const initialValue = {
    token: undefined,
    auth: null,
    isAuthLoaded: false,

}
export const getSession = async () => {
    const { data } = await axios.get<iUser>(
        "/api/auth/session", axiosConfig && axiosConfig()
    )
    return data
}
const AuthContext = createContext<AuthContext>({ auth: null, authLoaded: false, token: undefined })

const AuthProvider = ({ children }: Props) => {
    const [token, setToken] = useState<string | undefined>();
    // const { displayNote } = useNotify();
    const [auth, setAuth] = useState<iUser | null>(initialValue.auth)
    const [authLoaded, setAuthLoaded] = useState<boolean>(false)

    const [isConnected, setIsConnected] = useState(socket.connected);
  //  const dispatch = useAppDispatch();
    const mounted = useRef(false)
   
    useEffect(() => {
        const onConnect = () => {
            setIsConnected(true);
       //     socket.send('subscribeDevices', 0)

        }

        const onDisconnect = () => {
            setIsConnected(false);
        }


        if (auth !== null) {

        }


        
        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        //   socket.on('foo', onFooEvent);

        return () => {
        
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            //   socket.off('foo', onFooEvent);
        };
    }, []);


    useEffect(() => {

        if (!mounted.current) {
            mounted.current = true
            loadUser();
        }
    }, [])

    const signIn = (loginResponse: any): boolean => {
        const token = jwtDecode(loginResponse.token);
        console.log(token)
        setToken(loginResponse.token);

        if (token) {
            setAuth(loginResponse.user)
            window.localStorage.setItem('auth', JSON.stringify(loginResponse.user))
            window.localStorage.setItem('token', JSON.stringify(loginResponse.token))

            io(URL, {
                autoConnect: false,
                extraHeaders: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            });
            socket.connect()
            //socket.send('subscribeDevices')
            return true
        }

        return false
    }
    const signOut = (): boolean => {

        setAuth(null)
        window.localStorage.clear()
        return true

    }


    const loadUser = async () => {

        try {

            const user = await getSession()
            console.log(user)
            console.log( setAuth(user) );
            io(URL, {
                autoConnect: false,
                extraHeaders: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`
                }
            });
            socket.connect()

            setAuthLoaded(true)
            return user;
        } catch (ex) {
            console.log(ex)
            setAuthLoaded(true)
            // displayNote && displayNote("Error Getting Action Step Login Url", 'error')

        }


    }

    return (
        <AuthContext.Provider
            value={{ token, auth, authLoaded, loadUser, setAuth, signIn, signOut }}
        > 
          
 
            {children}
            <DebugButton data={isConnected} />
            {isConnected}
      
        </AuthContext.Provider>
    )
}
export const useAuth = () => useContext(AuthContext)
export default AuthProvider
