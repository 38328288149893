import { Route, Routes } from "react-router-dom";
import Area from "./area";
import AreaNew from "./area-new";
import Areas from "./areas";
import SecureRoute from "../../util/secureRoute";



const AreaRoutes = () => {

    return <Routes>
        <Route path="/" element={<SecureRoute title="Areas"><Areas /></SecureRoute>} />
        <Route path="/:id" element={<SecureRoute title="Area"><Area /></SecureRoute>} />
        <Route path="/new" element={<SecureRoute title="Area"><AreaNew /></SecureRoute>} />
    </Routes>
}


export default AreaRoutes;