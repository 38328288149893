import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import doRequest from '../../util/axios';
import TextInputField from '../../util/fields/text-field';
import FormContainer from '../../util/form-container';

interface dummyResponse {
    ok: boolean,
    d: any,
    // msg?: string,
}
const ForgotPassword = () => {
    const [d, setD] = useState<boolean>(false)

    return <>{d === false ? <FormContainer onSubmit={async ({ email, }) => {
        const response = await doRequest<dummyResponse>('auth/forgot-password', "POST", { email })
        console.log("forgot-password", response)
        if (response && response.ok === true) {
            setD(true);
        } else {
            if (response && response.d) {
                alert(response.d);
            } else {
                alert("Something was not right there, please try again.")
            }
        }
    }}
        requiredFields={['email']}
        buttonText="Recover Account"
        additionalButtons={<Button fullWidth><Link to="/login">Login</Link></Button>}
    >
        <TextInputField name="email" label="Email" type="email" />
    </FormContainer> : <Typography>Please check your email to recover your account</Typography>}
    </>

}


export default ForgotPassword