import { CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import UserForm from "./user-form";
import doRequest from "../../../util/axios";
const User = () => {
    const params = useParams();
    const [thing, setThing] = useState<any>();
    // const [tabsInfo, setTabsInfo] = useState<any[]>();

    useEffect(() => {
        const Go = async () => {
            const response = await doRequest<any>(`user/${params.id}`, 'GET', {})
            console.log("static", response)
            if (response) {
                setThing(response)

            }
        }
        Go();
    }, [params.id])


    if (thing === undefined) {
        return <CircularProgress />
    }

    return <Grid container spacing={2}>
        <Grid item md={12}>
            {JSON.stringify(thing)}
        </Grid>

        <UserForm title="Edit User" data={thing} buttonText="Save" />

    </Grid>
}


export default User