import { Helmet } from "react-helmet-async";
import { useAuth } from "./context";
import Nav from "./nav";
import {version} from "../../package.json"
import { SxProps } from "@mui/material";

interface iPageWrapper {
    title: string,
    children: React.ReactNode,
}

export const tableBoxHolderProps:SxProps= {
    height: {lg:'80vh',xs:"75vh",}, 

    width: "100%" 
}
const PageWrapper = ({ title, children, }: iPageWrapper) => {
    const { auth } = useAuth()
    return <div style={{ display: "flex" }}>
        <Helmet>
            <title>Plants / {title || ""}</title>
        </Helmet>

        <Nav />

        <main id="page-container"
            style={{
                flexGrow: 1,
                position: "relative",
                width: "100%",
                margin: "100px auto",
               maxWidth: "1700px",
                padding: "0 20px",
            }}
        >
            {/* <h2>{title}</h2> */}
            {children}
            <footer style={{textAlign:'center'}}>

                {/* <Link to="/legal">Legal</Link> */}
                <br />
                backend v{auth?.apiVersion} | client v{version}
            </footer>
        </main>


    </div>
}

export default PageWrapper;