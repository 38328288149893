
import {  IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { iSensor } from "../interfaces/iSensor";
import { useState } from "react";
import { SensorIconOnly } from "./sensor-chip";
// im honor of pat ;)
interface iSensorBurgerProp {
  sensors: iSensor[];
}
const SensorBurger = ({ sensors }: iSensorBurgerProp) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
      setAnchorEl(null);
  };

  return <>
      <IconButton onClick={handleClick}>{open ? <MenuOpenIcon /> : <MenuIcon />}</IconButton>
      <Menu

          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
              'aria-labelledby': 'basic-button',
          }}
      >
          {sensors.map((sensor, id) => {


              return <MenuItem key={id}>
                  <ListItemIcon>
                      <SensorIconOnly d={sensor} />
                  </ListItemIcon>
                  <ListItemText>{sensor.name}</ListItemText>
                  <Typography variant="body2" color="text.secondary">
                      {sensor.value}
                  </Typography>
              </MenuItem>
          })}
      </Menu>


  </>
}



export default SensorBurger