import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Button, CircularProgress, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import doRequest from "../../util/axios";
import DebugButton from "../../util/debugButton";
import DisplayDate from '../../util/display-date';
import DisplayDuration from '../../util/display-duration';
const Observations = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [things, setThings] = useState<any[]>()
    const navigate = useNavigate();
    useEffect(() => {
        const Go = async () => {
            if (loading === false) {
                setLoading(true)

                const response = await doRequest<any>("observation", "GET",)
                setThings(response)

            }
        }
        Go();
    }, []);



    const deleteThing = async (id: string) => {
        const b = window.confirm('Delete?')
        if (b) {

            const response = await doRequest<any>("observation", "DELETE", id)
            console.log(response)
            if (things) {
                const ind = things.findIndex(d => d._id === id)
                const newThings = [...things];

                newThings.splice(ind, 1);

                setThings(newThings)
            }
        }
    }
    if (things === undefined) {
        return <CircularProgress />
    }
    /*
    
    {
        field: d.F, disableColumnMenu: false, headerName: d.FN, width: d.W, renderCell: (params: GridRenderCellParams<string>) => {

            return <>unknown {d.T}</>


        },
    }
    */
    return <Grid container spacing={2}>
        <Grid item xs={12}>

            <Box>

                <div style={{ height: '500px', width: "100%" }}>
                    <Button variant="outlined" onClick={ev => {
                        ev.preventDefault();
                        navigate && navigate('./new')
                    }}>New</Button>
                    <DataGrid
                        getRowId={r => r._id}
                        disableColumnSelector
                        rows={things}
                        rowHeight={72}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'created', sort: 'asc' }],
                            },
                        }}
                        columns={[
                            {
                                field: 'image', disableColumnMenu: false, align: 'center', headerName: "", width: 72, renderCell: (params: GridRenderCellParams<any>) => {
                                    if (params.value && params.value[0] && params.value[0].fileName) {
                                        const src = `/api/uploads/${params.value[0].fileName}`;
                                        return <Avatar alt="" src={src} sx={{ width: 66, height: 66 }} />;
                                    } else {
                                        return <></>
                                    }
                                }
                            },
                            {
                                field: 'plant', disableColumnMenu: false, headerName: "Code", flex: 2, valueGetter: ({ value }) => value && value.code, minWidth: 100
                            },
                            {
                                field: 'created', disableColumnMenu: false, headerName: "Created", flex: 1, type: 'date', minWidth: 100,
                                renderCell: (params: GridRenderCellParams) => <>
                                    {params && params.value && <>
                                        <DisplayDate date={params && params.value} />
                                        <br />
                                        <DisplayDuration from={params && params.value} />

                                    </>}

                                </>,
                                valueGetter: ({ value }) => value && new Date(value),

                            },
                            {
                                field: '_id',
                                sortable: false,
                                disableColumnMenu: false,
                                headerName: '',
                                align: 'right',
                                headerAlign: 'right',
                                flex: 1,
                                minWidth: 150,
                                renderCell: (params: GridRenderCellParams) => {

                                    return <>
                                        <Button variant="outlined" onClick={() => navigate && navigate(`/observations/${params.value}`)}><EditIcon /></Button>
                                        <Button variant="outlined" onClick={async () => {
                                            await deleteThing(params.value)


                                        }}><DeleteIcon /></Button>
                                    </>

                                },
                            }

                        ]}
                        autoPageSize
                    />

                </div>
                <DebugButton data={things} />

            </Box >
        </Grid>

    </Grid>
}



export default Observations