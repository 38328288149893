import { Rating } from "@mui/material"
import { useState } from "react"

interface iRateingFieldProps{
    value: any
    update:(value:any)=>void
}
const RateingField = ({value, update}:iRateingFieldProps)=>{
    const [thisValue, setThisValue] = useState(()=>{
        if(value){
        return value
        } else {
            return undefined
        }
    })
    return     <Rating
    max={9}
    value={thisValue}
    onChange={(event, newValue) => {
        event
        setThisValue(newValue);
        update(newValue);
    }}
/>
}

export default RateingField;