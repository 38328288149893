import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { iDevice } from '../interfaces/iDevice';

// Define a type for the slice state
interface DevicesSlice {
  value: number;
  devices:iDevice[];
}

// Define the initial state using that type
const initialState: DevicesSlice = {
  value: 0,
  devices:[]
}

export const devicesSlice = createSlice({
  name: 'devices',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload
    },
    deleteDevice:(state, action:PayloadAction<string>) =>{
      state.devices = state.devices.filter((thing) => thing._id !== action.payload);
    },
    updateDevice:(state, action:PayloadAction<iDevice>) =>{


      if (action.payload._id) {

      
          let updated = false;
          const nextDevices = state.devices.map(mDevice => {
              if (action.payload._id === mDevice._id) {
                  updated = true
                  return action.payload;
              } else {
                  return mDevice
              }
          });
          if (updated === false) {
              nextDevices.push(action.payload)
          }
          // Re-render with the new array
          state.devices = nextDevices;
      }

   
    },

    setDevices:(state, action:PayloadAction<iDevice[]>) =>{
      state.devices = action.payload;
    }
  },
})

export const { increment, decrement, incrementByAmount, updateDevice, setDevices, deleteDevice} = devicesSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.devices.value

export default devicesSlice.reducer