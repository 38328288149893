import { Button, CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import doRequest from "../../util/axios";
import DataDump from "../../util/dataDump";
import SpeciesForm from "./species-form";
const Species = () => {
    const params = useParams();
    const [species, setSpecies] = useState<any>();
    const [edit, setEdit] = useState<boolean>(true);
    useEffect(() => {
        const Go = async () => {
            const response = await doRequest<any>(`species/${params.id}`, 'GET', {})
            console.log("static", response,)
            if (response) {
                setSpecies(response)

            }
        }
        Go();
    }, [params.id])


    if (species === undefined) {
        return <CircularProgress />
    } else {
        return <Grid container spacing={2}>
            <Button onClick={() => setEdit(!edit)}>Edit</Button>
            {edit ? <Grid item md={12}>
                <SpeciesForm title="Edit Species" data={species} buttonText="Save" />
            </Grid> : <Grid item md={12}>
                <DataDump data={species} />

            </Grid>}
        </Grid>
    }
}




export default Species