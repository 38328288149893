import { useState } from "react";
import { iPlant } from "../interfaces/iPlant";
import { iToDo } from "../interfaces/iToDo";
import { Avatar, Box, Button, CircularProgress, Modal, Typography } from "@mui/material";
import { popupStyle } from "./popup";
import { ObservationFastForm } from "./observation-fast";

export interface iMultiThing{
    plant:iPlant,
    todo:iToDo
}

interface iMultipleTodos{
    toDos:iMultiThing[]
    done:()=>void;
}
const MultipleTodos = ({toDos}:iMultipleTodos)=>{
    const [index, setIndex] = useState(0)
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [plant, setPlant] = useState<iPlant|undefined>(()=>{
        if(toDos.length>0){
            return  toDos[0].plant
        } else {
            return undefined;
        }
    })
    const [d, setD] = useState<iToDo|undefined>(()=>{
        if(toDos.length>0){
            return  toDos[0].todo
        } else {
            return undefined;
        }
    })

    const done = ()=>{
        setLoading(true);
        console.log("move to next");
        const newIndex = index +1;
        if(toDos[newIndex]){
            setPlant(toDos[newIndex].plant)
            setD(toDos[newIndex].todo)
            setIndex(newIndex)
        } else {
            handleClose();
            done();
        }
        setTimeout(()=>setLoading(false),1000);
        
        
    }
    let src = '';
    if (plant && plant.displayImage && plant.displayImage.displayUrl) {
        src = `/api/uploads/${plant.displayImage.displayUrl}`;
    }
    return  <><Button size="small" variant="outlined" onClick={handleOpen}>Do All</Button>
    
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={popupStyle}>
            {loading===true?<Box sx={{textAlign:'center'}}><CircularProgress sx={{mt:25}} /></Box>:<>
            
            <Typography variant="h6">
           {plant && plant.name}
            </Typography>

            <Avatar alt="" src={src} sx={{
                    m: 'auto',
                    mt: 2,
                    height: { md: "20vw", xs: "75vw" },
                    width: { md: "20vw", xs: "75vw" },
                }} />
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {d && d.type && d.type.name}
                </Typography>
            {plant&& d && d.type && d.type.scheme && <ObservationFastForm plant={plant} todo={d} schema={d.type.scheme} additionalEndButton={<Button fullWidth variant="outlined" onClick={()=>{
                done()
            }}>Skip</Button>} done={() => {
               // handleClose();
                done()
            }} />}</>}
            

        </Box>
    </Modal>
    </>
}
export default MultipleTodos