import { CircularProgress, Grid, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Field, FormSpy } from "react-final-form";
import doRequest from "../axios";
import { composeValidators, requiredValidate } from "./validators";

interface iDBSelectInput {
    name: string;
    label: string;
    helperText?: string;
    requestUrl: string;
    required?: boolean;
    definedList?: { _id: string, name: string }[]
    requestType: 'POST' | 'GET';
}
// https://mui.com/material-ui/react-autocomplete/





const SelectField = ({ name, label, requestUrl, requestType,  definedList, required }: iDBSelectInput) => {

    //  const ml = multiline;

    const [loading, setLoading] = useState<boolean>(false);
    const [things, setThings] = useState<any[]>(() => {
        if (definedList) {
            return definedList
        } else {
            return []
        }

    })

    useEffect(() => {
        const Go = async () => {
            if (!definedList) {
                if (loading === false) {
                    setLoading(true)

                    const response = await doRequest<any>(requestUrl, requestType,)
                    setThings(response)

                }
            }

        }
        Go();
    }, []);





    let fieldProps: any = {
        name,
    }
    if (required === true) {
        fieldProps.validate = composeValidators(requiredValidate)
    }

    let textFiledProps: any = {
        autoComplete: 'off',
        id: "outlined-select-currency",
        select: true,
        label,
        required,
        fullWidth: true,
    }

    return <Grid item xs={12}>
        {things === undefined ? <CircularProgress /> : <FormSpy
            subscription={{
                values: true,
                dirtySinceLastSubmit: true,
                submitting: true,
            }}
        >
            {({ dirtySinceLastSubmit, submitting }) => (



                <Field {...fieldProps}>
                    {({ input: { onChange, value }, meta }) => {
                        // const checked = (value ? value : false)
                        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                            onChange(event.target.value);
                        };

                        return <>
                            <TextField

                                value={value}
                                onChange={handleChange}
                                error={
                                    (meta.error && meta.touched) ||
                                    (!!meta.submitError &&
                                        !dirtySinceLastSubmit &&
                                        !submitting)
                                }
                                helperText={
                                    meta.error && meta.touched
                                        ? meta.error
                                        : !!meta.submitError &&
                                            !dirtySinceLastSubmit &&
                                            !submitting
                                            ? meta.submitError
                                            : ""
                                }

                                {...textFiledProps}>
                                {things && things.map((option: any, index: number) => {
                                    const optionA = (definedList === undefined ? option : option._id);

                                    return <MenuItem key={index} value={optionA}>
                                        {option.name}
                                    </MenuItem>
                                })}
                            </TextField>

                        </>
                    }}
                </Field>
            )}
        </FormSpy>}

    </Grid>
}


export default SelectField;