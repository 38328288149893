import LoginIcon from '@mui/icons-material/Login';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import doRequest from '../../util/axios';
import { useAuth } from '../../util/context';
import TextInputField from '../../util/fields/text-field';
import FormContainer from '../../util/form-container';


const Login = () => {

    const { signIn} = useAuth();
    const navigate = useNavigate();
    return <FormContainer onSubmit={async ({ emailAddress, password }) => {
        //    await doLogin(email, password, rememberMe)
        const response = await doRequest<any>('auth/login', "POST", { emailAddress, password })
        console.log("login", response)
        if (response && response) {
            signIn && await signIn(response)
            navigate("/", { replace: true });

        }
    }}
        requiredFields={['emailAddress', 'password']}
        buttonText="Login"
        additionalButtons={<Button fullWidth><Link to="/login/forgot-password">Forgot Password</Link></Button>}
        icon={<LoginIcon />}
    >
        <TextInputField name="emailAddress" label="Email" type="email" />
        <TextInputField name="password" label="Password" type="password" />
    </FormContainer>

}


export default Login