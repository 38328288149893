import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Button, ButtonGroup, CircularProgress, Divider, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import Barcode from 'react-barcode';
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';
import doRequest from "../../util/axios";
import DebugButton from "../../util/debugButton";
import TodoChip from '../../util/todo-chip';
import { iPlant } from '../../interfaces/iPlant';
import { tableBoxHolderProps } from '../../util/pageWrapper';
import SensorBurger from '../../util/sensor-burger';
import GotoButton from '../../util/goto-button';

const Plants = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const holder = useRef<HTMLDivElement>(null);
    const [things, setThings] = useState<iPlant[]>()
    const navigate = useNavigate();
    const handlePrint = useReactToPrint({
        content: () => holder.current,
    });
    useEffect(() => {
        const Go = async () => {
            if (loading === false) {
                setLoading(true)
                const response = await doRequest<iPlant[]>("plant", "GET",)
                setThings(response)
            }
        }
        Go();
    }, []);
    const deleteThing = async (id: string) => {
        const b = window.confirm('Delete?')
        if (b) {
            const response = await doRequest<any>("plant", "DELETE", id)
            console.log(response)
            if (things) {
                const ind = things.findIndex(d => d._id === id)
                const newThings = [...things];

                newThings.splice(ind, 1);
                setThings(newThings)
            }
        }

    }
    if (things === undefined) {
        return <CircularProgress />
    }

    return <Grid container spacing={2}>
        <Grid item xs={12}>

            <Box>

            <Box sx={tableBoxHolderProps}>
                    <Button variant="outlined" onClick={ev => {
                        ev.preventDefault();
                        navigate && navigate('/plants/new')
                    }}>New</Button>
                    <DataGrid
                        getRowId={r => r._id}
                        disableColumnSelector
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'bornDate', sort: 'asc' }],
                            },
                        }}
                        rows={things}
                        rowHeight={72}
                        columns={[
                            {
                                field: 'displayImage', disableColumnMenu: true, align: 'center', headerName: "", renderCell: (params: GridRenderCellParams<any>) => {
                                    if (params.value && params.value.displayUrl) {
                                        const src = `/api/uploads/${params.value.displayUrl}`;
                                        return <Avatar alt=""  onClick={() => navigate && navigate(`/plants/${params.row._id}`)} src={src} sx={{ width: 66, height: 66 }} />;
                                    } else {
                                        return <></>
                                    }
                                }
                            },
                            {
                                field: 'code', disableColumnMenu: true, headerName: "Code", flex: 0.7,minWidth: 100,
                            },
                            {
                                field: 'species', disableColumnMenu: true, headerName: "Species", minWidth: 120,flex: 1, valueGetter: ({ value }) => value && value.name,
                                renderCell:(params: GridRenderCellParams) => <>{params.value} {params.row.species && <GotoButton to={`/species/${params.row.species._id}`}/>}</>,
                            },
                            {
                                field: 'area', disableColumnMenu: true, headerName: "Area", minWidth: 105,flex: 0.7, valueGetter: ({ value }) => value && value.name,
                                renderCell:(params: GridRenderCellParams) => <>{params.value} {params.row.area &&<GotoButton to={`/areas/${params.row.area._id}`}/>}</>,
                            },  
                    
                            {   
                                field: 'toDos', disableColumnMenu: true, headerName: "To Do", align: 'right',minWidth: 150,
                                headerAlign: 'right', renderCell: (params: GridRenderCellParams) => {
                                    //    const senseArray
                                    console.log(params.row)
                                    return <>
                                      {params.row.toDos && params.row.toDos.map((d: any, di: number) => {
                                            return <TodoChip key={di} d={d} index={di} plant={params.row} done={async () => {
                                                setTimeout(async ()=>{
                                                    const response = await doRequest<iPlant[]>("plant", "GET",)
                                                setThings(response)
                                                }, 200)
                                            }} />
                                        })}
                                    </>
                                }
                            },
                            {
                                field: '_id',
                                sortable: false,
                                disableColumnMenu: true,
                                headerName: '',
                                align: 'right',
                                headerAlign: 'right',
                                flex: 1,
                                minWidth: 150,
                                renderCell: (params: GridRenderCellParams) => {
                                    
                                    return <>
                                       <ButtonGroup>
                                        <DebugButton data={params.row} />
                                        <SensorBurger sensors={params.row.sensors} />
                                        <IconButton onClick={() => navigate && navigate(`/plants/${params.row._id}`)}><EditIcon /></IconButton>
                                        <IconButton color='error' onClick={async () => {
                                            await deleteThing(params.row._id)

                                        }}><DeleteIcon /></IconButton></ButtonGroup>
                                    </>

                                },
                            }

                        ]}
                        autoPageSize
                    />
                </Box>
                <DebugButton data={things} />
            </Box >
        </Grid>
        <Grid item xs={12}>

            {handlePrint && <Button type="button" className="btn btn-info" onClick={(ev: React.MouseEvent) => {
                ev.preventDefault();
                handlePrint();
            }}>
                Click Here to Print Label
            </Button>}
            <div style={{visibility:'hidden'}} >

                <Box sx={{ width: "100%", padding: 2 }} ref={holder}>
                    <Grid container spacing={1}>
                        {things.map((plant, index) => {

                            const div = index % 2 == 0;
                            console.log(div)
                            return <div key={index}><Grid item md={4} className="dontBreak">
                                <Box sx={{ border: 1, borderColor: "black", textAlign: "center", padding: 3, margin: 1 }}>

                                    <Box sx={{ width: "100%" }}>
                                        <Typography variant="h3">{plant.code}</Typography>

                                        <Typography>{plant.species.name}</Typography>
                                        <Divider style={{ width: '90%' }} />
                                        <Typography sx={{ fontStyle: 'italic' }}>{plant.species.scienceName}</Typography>
                                        <Divider style={{ width: '90%' }} />
                                        <Barcode value={plant.code} options={{ displayValue: false }} />;

                                    </Box>

                                </Box>

                            </Grid>

                                {div && <div className='printBreak' />}
                            </div>
                        })}


                    </Grid>
                </Box>




            </div>

        </Grid>
    </Grid >
}



export default Plants