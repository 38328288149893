import doRequest from "../../util/axios";
import AreaForm from "./area-form";
const AreaNew = () => {

    return <AreaForm onSubmit={(async (d: any) => {
        const n = await doRequest<any>('area', "POST", d)
        console.log(n)
    })} data={{}} />
}


export default AreaNew