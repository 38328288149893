import { FormControlLabel, Grid, Switch } from "@mui/material";
import { Field } from "react-final-form";

interface iTextInput {
    name: string;
    label: string;

}

const CheckboxInputField = ({ name, label, }: iTextInput) => {

    //  const ml = multiline;


    return <Grid item xs={12}>



        <Field name={name}>
            {({ input: { onChange, value } }) => {
                const checked = (value ? value : false)
                const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(event.target.checked);
                };

                return <FormControlLabel control={<Switch
                    color="primary"
                    checked={checked}
                    onChange={handleChange}
                    name="focusDisabledItems"
                />} label={label} />



            }}
        </Field>

    </Grid>
}


export default CheckboxInputField;