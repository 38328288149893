import { Autocomplete, Box, Button, FormControl,  InputLabel, MenuItem, Select, TextField } from "@mui/material";
;

import { useNavigate } from "react-router-dom";
import doRequest from "../../util/axios";

import { useEffect, useRef, useState } from "react";
import { iSystemSchema } from "../../interfaces/iSystemSchema";

import { iObservation } from "../../interfaces/iObservation";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { iPlant } from "../../interfaces/iPlant";
import { useNotify } from "../../util/notify";
import ObservationQuestionsSubForm from "../../util/hookformfields/observation-subform";
import { iArea } from "../../interfaces/iArea";
import { DataSource } from "../../interfaces/enums";

const ObservationForm = ({  data }: any) => {
    const navigate = useNavigate();
    useNotify();

    const methods = useForm<iObservation>({ defaultValues: { id: 'new', data: {}, schemaa: '', ...data } })

    const onSubmit: SubmitHandler<iObservation> = async (data) => {

        console.log(data)
        const n = await doRequest<any>('observation', "POST", data)
        console.log(n)
        navigate('/observations')


    }
    const { watch, handleSubmit, setValue, control } = methods;
    const schemaData = watch('schemaData');
  
    const mounted = useRef(false);
    const [schemas, setSchemas] = useState<iSystemSchema[]>([]);
    const [plants, setPlants] = useState<iPlant[]>([]);
    const [areas, setAreas] = useState<iArea[]>([]);

    useEffect(() => {
        const Go = async () => {
            //get schemas

            const areasA = await doRequest<iArea[]>(`area`, "GET");
            console.log(areasA)
            areasA && setAreas(areasA);
            const plantsA = await doRequest<iPlant[]>(`plant`, "GET");
            console.log(plantsA)
            plantsA && setPlants(plantsA);
            const schemasA = await doRequest<iSystemSchema[]>(`SystemScheme`, "GET");
            console.log(schemasA)
            schemasA && setSchemas(schemasA);
        }
        if (mounted.current === false) {
            mounted.current = true;
            Go();
        }
    }, [])
const showThing=(d:string)=>{
   
return (schemaData && schemaData.requiredDataSchemes.findIndex((ds)=>{

        if(d.trim() === ds.toString())
        {return true}else{

        return false;
        }
    }
    )>=0)
}



    return <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>

        <Controller
            control={control}
            name="schemaa"
            rules={{
                required: true, minLength: 10
            }}
            render={({
                field,
            }) => (
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Questions</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={field.value}
                        label="Age"
                        onChange={(event) => {
                            field.onChange(event.target.value)
                            const s = schemas && schemas.find((d) => { return d._id === event.target.value });
                         
                       //    s && setValue('_sources', s.requiredDataSchemes)
                           setValue('schemaData', s)
                           
                        }}
                    >
                        {schemas && schemas.map((s, i) => {

                            return <MenuItem key={i} value={s._id}>{s.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>)} />
{schemaData && JSON.stringify(schemaData.requiredDataSchemes.includes("area"))}
                {showThing("area") && 
<Controller
            control={control} rules={{
                required: true
            }}

            name="area"
            render={({
                field: { value, ref, onChange, ...field },
                fieldState,
            }) => {
                const errorBool = (fieldState.error ? true : false)
                const helperText = (fieldState.error ? fieldState.error.message : "")

                return <Autocomplete
                    value={value}
                    options={areas}
                    onChange={(_, data) => {
                        onChange(data);
                    }}

                    getOptionLabel={(option) => {
                        if (typeof option !== 'string') {
                            return option.name
                        } else {
                            return option
                        }
                    }}
                    renderOption={(props, option) => {

                        console.log(option)
                        if (typeof option !== 'string') {
                            return <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

                                {option._id && <> {option.name}</>}
                            </Box>
                        } else {
                            return <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option}
                            </Box>
                        }
                    }}
                    renderInput={(params) => {
                    
                    return <TextField
                        {...params}
                        {...field}
                        fullWidth
                        helperText={helperText}
                        error={errorBool}
                        inputRef={ref}
                        variant="standard"
                        label="Area"


                    />}} />

            }}
        />}




{showThing(DataSource.Plant) && 
<Controller
            control={control} rules={{
                required: true
            }}

            name="plant"
            render={({
                field: { value, ref, onChange, ...field },
                fieldState,
            }) => {
                const errorBool = (fieldState.error ? true : false)
                const helperText = (fieldState.error ? fieldState.error.message : "")

                return <Autocomplete
                    value={value}
                    options={plants}
                    onChange={(_, data) => {
                        onChange(data);
                    }}

                    getOptionLabel={(option) => {
                        if (typeof option !== 'string') {
                            return option.name
                        } else {
                            return option
                        }
                    }}
                    renderOption={(props, option) => {

                        console.log(option)
                        if (typeof option !== 'string') {
                            return <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

                                {option._id && <> {option.name}</>}
                            </Box>
                        } else {
                            return <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option}
                            </Box>
                        }
                    }}
                    renderInput={(params) => {
                    
                    return <TextField
                        {...params}
                        {...field}
                        fullWidth
                        helperText={helperText}
                        error={errorBool}
                        inputRef={ref}
                        variant="standard"
                        label="Plant"


                    />}} />

            }}
        />}


{JSON.stringify(schemaData)}
<ObservationQuestionsSubForm />


        <Button type="submit">Go</Button>
    </form>
    </FormProvider>



}


export default ObservationForm;