import {  TextField } from "@mui/material"
import { useState } from "react"

interface iTextFieldProps{
    value: any
    update:(value:any)=>void
}
const TTextField = ({value, update}:iTextFieldProps)=>{
    const [thisValue, setThisValue] = useState(()=>{
        if(value){
        return value
        } else {
            return ""
        }
    })
    return     <TextField
    fullWidth
    type="text"
    value={thisValue}
    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setThisValue(event.target.value);
        update(event.target.value);
    }}
/>
}

export default TTextField;