import { Avatar, Box, CircularProgress, Grid, Tooltip, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import doRequest from "../../util/axios";

import { iPlant } from "../../interfaces/iPlant";
import { useNavigate } from "react-router-dom";
import TodoChip from "../../util/todo-chip";
import { iArea } from "../../interfaces/iArea";
import SensorChip from "../../util/sensor-chip";
import DebugButton from "../../util/debugButton";
import MultipleTodos from "../../util/multi-thing";
interface AAreaProp {
    index: number;
    showIndex: number;
    area: iArea;
    setShowIndex:(x:number)=>void;
    Go: () => void
}
const AArea = ({ area, Go, index, showIndex, setShowIndex }: AAreaProp) => {
    const navigate = useNavigate();

    return <Grid key={area._id} xs={12} sx={{ border: 2, borderRadius: 4, borderColor: 'lightgray', mb: 2, pt: 2, pb: 2 }}>
        <Typography variant="h4" sx={{ position: { md: 'relative' }, mb: 2, pl: 2, pr: 2 }} onClick={()=>{
            setShowIndex(index)
        }}>
            {area.name}
            <Box sx={{ position: { md: 'absolute' }, right: 0, top: 0, pr: 2 }}>

                <DebugButton data={area} />
                {area._toDosMulti && <MultipleTodos toDos={area._toDosMulti.filter(d => d.todo.type !== null && d.todo.type._id === 'complete.plantBasic.observation')} done={Go} />}

                {area.toDos && area.toDos.map((d: any, di: number) => {
                    return <TodoChip key={di} d={d} index={di} area={area} done={async () => {
                        setTimeout(async () => {
                            console.log("go")
                            Go();
                        }, 2000)
                    }} />
                })}

                {area.sensors.map((d: any, di: number) => {
                    return <SensorChip key={di} d={d} />
                })}

            </Box>
        </Typography>
        {index === showIndex && <Grid container spacing={2} sx={{ pl: 2, pr: 2 }}>
            {area._plants && area._plants.map(plant => {

                let src = '';
                if (plant.displayImage && plant.displayImage.displayUrl) {
                    src = `/api/uploads/${plant.displayImage.displayUrl}`;
                }
                let tempWarning: null | boolean = null;
                if (plant.species && plant.species.tempHigh && plant.species.tempLow) {
                    if (area._currentTemp) {
                        if (area._currentTemp >= plant.species.tempLow && area._currentTemp <= plant.species.tempHigh) {
                            tempWarning = false;
                        } else {
                            tempWarning = true;
                        }
                    }
                }
                let aitHumidityWarning: null | boolean = null;
                if (plant.species && plant.species.airHumidityLow && plant.species.airHumidityHigh) {
                    if (area._currentAirHumidity) {
                        if (area._currentAirHumidity >= plant.species.airHumidityLow && area._currentAirHumidity <= plant.species.airHumidityHigh) {
                            aitHumidityWarning = false;
                        } else {
                            aitHumidityWarning = true;
                        }
                    }
                }


                return <Grid item xs={12} sm={6} md={3}>
                    <Box sx={{ border: 1, borderRadius: 2, borderColor: 'lightgray', textAlign: "center", p: 1, pt: 2 }}>

                        <Avatar variant="rounded" onClick={() => {
                            navigate(`/plants/${plant._id}`)
                        }} alt="" src={src} sx={{ width: { xs: "80vw", sm: "30vw", md: "19vw" }, height: { xs: "80vw", sm: "30vw", md: "19vw" }, margin: 'auto' }} />

                        <Tooltip title={plant.species.name}>
                            <Typography sx={{ fontWeight: 'bold', pt: 1 }}>
                                {plant.name}
                            </Typography>
                        </Tooltip>
                        <Typography>
                            {plant.toDosOutstanding} - {plant.toDosComplete}
                        </Typography>
                        {plant.toDos && plant.toDos.map((d: any, di: number) => {
                            return <TodoChip key={di} d={d} index={di} plant={plant} done={async () => {
                                setTimeout(async () => {
                                    console.log("go")
                                    Go();
                                }, 2000)
                            }} />
                        })}
                        {plant.sensors.map((d: any, di: number) => {
                            return <SensorChip key={di} d={d}  />
                        })}

                        {plant.species && plant.species.tempHigh && plant.species.tempLow && <Typography>
                            Temp
                            {tempWarning !== null && tempWarning === true && "Warning"}
                            <br />
                            ({plant.species.tempLow} -  {plant.species.tempHigh})
                        </Typography>}


                        {plant.species && plant.species.airHumidityLow && plant.species.airHumidityHigh && <Typography>
                            Air Humidity
                            {aitHumidityWarning !== null && aitHumidityWarning === true && "Warning"}
                            <br />
                            ({plant.species.airHumidityLow} - {plant.species.airHumidityHigh})
                        </Typography>}

                        <DebugButton data={plant} />
                    </Box>
                </Grid>
            })}
        </Grid>}



    </Grid>
}

const Home = () => {
    const mounted = useRef(false)
    const [loading, setLoading] = useState<boolean>(false);
    const [areas, setAreas] = useState<iArea[]>()
    const [showIndex, setShowIndex] =  useState(0)
    const average = (array?: number[]) => {
        if (array && array.length > 0) {
            return array.reduce((a, b) => a + b) / array.length;
        } else {
            return undefined
        }
    }
    const Go = async () => {

        setLoading(true)

        let rAreas = await doRequest<iArea[]>("area", "GET")
        if (rAreas) {
            rAreas = rAreas.map(dd => {
                dd._currentTemp = average(dd.sensors.filter(sense => {
                    if (sense.type === 'temp') {
                        return true;
                    } else {
                        return false;
                    }
                }).map(sense => {
                    return sense.value;
                })
                );
                dd._currentAirHumidity = average(dd.sensors.filter(sense => {
                    if (sense.type === 'hum') {
                        return true;
                    } else {
                        return false;
                    }
                }).map(sense => {
                    return sense.value;
                })
                );

                dd._plants = [];
                dd._toDosMulti = [];
                return dd;
            })

            const rPlants = await doRequest<iPlant[]>("plant", "GET",)
            if (rPlants) {
                for (const plant of rPlants) {
                    if (plant.area && typeof plant?.area !== 'string' && plant.area._id) {
                        const areaIndex = rAreas.findIndex(d => typeof plant?.area !== 'string' && d._id === plant.area._id);
                        if (areaIndex >= 0) {
                            const area = rAreas[areaIndex];
                            area._plants?.push(plant)
                            if (plant.toDos) {
                                for (const todo of plant.toDos) {
                                    area._toDosMulti?.push({ plant, todo })
                                }
                            }
                            rAreas[areaIndex] = area;
                        }
                    }
                }
            }

            setAreas(rAreas)
            setLoading(false)
        }
    }
    useEffect(() => {
        if (mounted.current === false) {
            mounted.current = true;
        }
        Go();
    }, []);
    if (loading === true) {
        return <CircularProgress />
    }


    return <Grid container spacing={2}>
        {areas && areas.filter(a => a?._plants?.length && a?._plants?.length > 0).map((area, indexD) => {
            return <AArea index={indexD} showIndex={showIndex} setShowIndex={setShowIndex} area={area} Go={Go} />
        })}









        {/* <Grid item sm={12} md={6}>
            <Typography variant="h4"> Todos</Typography>

            {things && things.map(todo => {
                return <TodoChipPlant key={todo._id} d={todo} plant={todo.plant} done={async () => {
                    const response = await doRequest<iToDo[]>("to-do", "GET",)
                    response && setThings(response.filter(d => d.complete === false))
                }} />



            })}

        </Grid> */}
    </Grid>
}


export default Home