import { Grid } from "@mui/material";

interface iDump {
    data: any;

}
const DataDump = ({ data }: iDump) => {
    const ent = Object.entries(data)
    return <Grid container>
        {ent.map((v, index) => {
            const key = v[0]
            const dataA = data[key]
            return <Grid md={6} item key={index}>{key} = {dataA}</Grid>
        })}

    </Grid>
}



export default DataDump;