
import { Avatar, Box, IconButton, Modal, Tooltip, Typography } from "@mui/material";

import { iToDo } from '../interfaces/iToDo';

import { iPlant } from "../interfaces/iPlant";
import { useState } from "react";
import { ObservationFastForm } from "./observation-fast";
import { popupStyle } from "./popup";
import { TodoLogoChip } from "./todo-utils";
import { iArea } from "../interfaces/iArea";

interface iTodoChip {
    d: iToDo;
    index: number;
    done: () => void;
    plant?: iPlant
    area?: iArea;
    //   sx?:SxProps;
}
const TodoChip = ({ d, plant,area, done }: iTodoChip) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    if (d.type) {
        const code = d.type.id;

        let src = '';
        if (plant && plant.displayImage && plant.displayImage.displayUrl) {
            src = `/api/uploads/${plant.displayImage.displayUrl}`;
        }
        if (area  && area.image && area.image.displayUrl) {
            src = `/api/uploads/${area.image.displayUrl}`;
        }
        return <>
        <Tooltip title={d.type.name}>
            <IconButton onClick={() => {
            handleOpen();
        }}>
            <TodoLogoChip code={code} todo={d} />
        </IconButton>
        </Tooltip>

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={popupStyle}>
                <Typography variant="h6">
                {plant  &&plant.area &&   typeof plant.area !=='string' && plant.area.name}-{ plant && plant.name}-{d.name}

                </Typography>

                <Avatar alt="" src={src} sx={{
                    m: 'auto',
                    mt: 2,
                    height: { md: "20vw", xs: "75vw" },
                    width: { md: "20vw", xs: "75vw" },
                }} />
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {d.type.name}
                </Typography>
                {d.type.scheme && <ObservationFastForm plant={plant} area={area} todo={d} schema={d.type.scheme} done={() => {
                    handleClose();
                    done()
                }} />}

            </Box>
        </Modal>

        </>
    } else {
        return <></>
    }


}


export default TodoChip