import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { socket } from "../../../util/context";
import { clearMqttLog, newMqttLog } from "../../../store/admin-slice";
import { Box, Button, Typography } from "@mui/material";
import DisplayDate from "../../../util/display-date";

const MqttLogViewer = ()=>{
    const mqttLog = useAppSelector((state) => state.admin.mqttLog)
    const someElement = useRef<HTMLDivElement>();
    const dispatch = useAppDispatch();
    
    const setScrollPosition = () => {
        if(someElement.current){
            window.scrollTo({
                top: someElement.current.offsetTop,
                behavior: "smooth"
            });
        }
    };
    useEffect(()=>{
        setScrollPosition()
    }, [mqttLog])
    return <>
    <Box>
        {Object.entries(mqttLog).map((log, iLog)=><Typography key={iLog}>
            <DisplayDate date={log[1].ts}/>
            {log[1].topic} {log[1].message}
            </Typography>)}
        <Box ref={someElement} />
    </Box>
    <Button fullWidth variant="outlined" onClick={()=>{
          dispatch(clearMqttLog())
    }}>Clear</Button>
    </>
}


const Debug = ()=>{
    const dispatch = useAppDispatch();
    // const mounted = useRef(false)
    const onMqttLog = async (payload: any) => {
        console.log("onMqttLog", payload)
        dispatch(newMqttLog(payload))
    }
    useEffect(() => {
        socket.emit('subscribeMqttLog')
     
        socket.on('mqttLog', (device) => onMqttLog(device));
        return () => {
            socket.emit('unSubscribeMqttLog')
            socket.off('mqttLog', (device) => onMqttLog(device));
        };
    }, []);

    return <MqttLogViewer />
}

export default Debug;