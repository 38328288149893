import { HelmetProvider } from "react-helmet-async";
import 'react-html5-camera-photo/build/css/index.css';
import {
  BrowserRouter
} from "react-router-dom";
import './App.css';
import IndexRoutes from "./pages";
import AuthProvider from "./util/context";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { Provider } from "react-redux";
import { store } from "./store/store";


const App = () => {

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  
 // console.log(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`)
  return <HelmetProvider>
      <ThemeProvider theme={darkTheme}>
      <CssBaseline/> 
      <Provider store={store}>
        <AuthProvider>
            
          <BrowserRouter>
            <IndexRoutes />

          </BrowserRouter>

        </AuthProvider>
      </Provider>
    </ThemeProvider>
  </HelmetProvider>

}

export default App;
