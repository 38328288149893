
import PlantForm from "./plant-form";
const PlantNew = () => {

    return <PlantForm title="New Plant" buttonText="New" onSubmit={(async () => {
       
    })} data={{}} />
}


export default PlantNew