import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, ButtonGroup, CircularProgress, Grid, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import doRequest from "../../util/axios";
import DebugButton from "../../util/debugButton";
import GotoButton from '../../util/goto-button';
import DisplayDate from '../../util/display-date';
import DisplayDuration from '../../util/display-duration';
const Sensors = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [things, setThings] = useState<any[]>()
    const navigate = useNavigate();

    useEffect(() => {
        const Go = async () => {
            if (loading === false) {
                setLoading(true)

                const response = await doRequest<any>("sensor", "GET",)
                setThings(response)

            }
        }
        Go();
    }, []);

    const deleteThing = async (id: string) => {
        const b = window.confirm('Delete?')
        if (b) {

            const response = await doRequest<any>("sensor", "DELETE", id)
            console.log(response)
            if (things) {
                const ind = things.findIndex(d => d._id === id)
                const newThings = [...things];

                newThings.splice(ind, 1);

                setThings(newThings)
            }
        }
    }

    if (things === undefined) {
        return <CircularProgress />
    }

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <Box>
                <div style={{ height: '500px', width: "100%" }}>
                    <Button variant="outlined" onClick={ev => {
                        ev.preventDefault();
                        navigate && navigate('./new')
                    }}>New</Button>
                    <DataGrid
                        getRowId={r => r._id}
                        disableColumnSelector
                        rows={things}
                        rowHeight={72}
                        columns={[
                            {
                                field: 'device', disableColumnMenu: false, headerName: "Device", flex: 2,
                                valueGetter: ({ value }) => value && value.code + " " + value.name, minWidth: 250
                            },
                            {
                                field: 'plant', disableColumnMenu: false, headerName: "Plant", flex: 2,
                                valueGetter: ({ value }) => value && value.name, minWidth: 200,
                                renderCell: (params: GridRenderCellParams) => <>{params.value}{params.row.plant && <GotoButton to={`/plants/${params.row.plant._id}`} />}</>,
                            },
                            {
                                field: 'area', disableColumnMenu: false, headerName: "Area", flex: 2, minWidth: 200,
                                valueGetter: ({ value }) => value && value.name,
                                renderCell: (params: GridRenderCellParams) => <>{params.value}{params.row.area && <GotoButton to={`/areas/${params.row.area._id}`} />}</>,
                            },
                            {
                                field: 'lastConnected', disableColumnMenu: false, headerName: "Last Connected", flex: 1, minWidth: 175, type: 'datetime',
                                renderCell: (params: GridRenderCellParams) => <>
                                    {params && params.value && <>
                                        <DisplayDate date={params && params.value} />
                                        <br />
                                        <DisplayDuration from={params && params.value} />

                                    </>}
                                </>,
                                valueGetter: ({ value }) => value && new Date(value),
                            },
                            {
                                field: 'type', disableColumnMenu: false, headerName: "Type", flex: 1, minWidth: 100
                            },
                            {
                                field: 'value', disableColumnMenu: false, headerName: "Value", flex: 2, minWidth: 150
                            },
                            {
                                field: '_id',
                                sortable: false,
                                disableColumnMenu: false,
                                headerName: '',
                                align: 'right',
                                headerAlign: 'right',
                                flex: 1,
                                minWidth: 150,

                                renderCell: (params: GridRenderCellParams) => {
                                    return <ButtonGroup>
                                        <DebugButton data={params.row} />
                                        <IconButton onClick={() => navigate && navigate(`/sensors/${params.row._id}`)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={async () => {
                                            await deleteThing(params.row._id)
                                        }}><DeleteIcon /></IconButton>

                                    </ButtonGroup>

                                },
                            }

                        ]}
                        autoPageSize
                    />

                </div>
                <DebugButton data={things} />

            </Box >
        </Grid>

    </Grid>
}



export default Sensors