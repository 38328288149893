import { IconButton } from "@mui/material"
import { Link } from "react-router-dom"
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
interface iGotoButtonProp{
    to:string;
}
const GotoButton = ({to}:iGotoButtonProp) =>{

    return <IconButton component={Link} to={to}><ArrowOutwardIcon /></IconButton>
}

export default GotoButton;